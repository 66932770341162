import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import image from "../../images/go store.png"
import { useNavigate } from 'react-router-dom'; // Import useHistory from react-router-dom

const pages = ['الرئيسية', 'الاوردرات'];
const settings = ['الطلبات','الموردين','جوستوك', 'الرئيسية', 'الرسائل', 'تسجيل خروج'];

function HeaderAdmin() {
  const history = useNavigate(); // Initialize useHistory hook

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSignOut = () => {
    // Remove adminToken from local storage
    localStorage.removeItem('adminToken');
    // Navigate to "/admin"
    history('/admin');
  };

  const handleMessagesClick = () => {
    history('/contactsmessages'); // Navigate to "/contactsmessages" when clicking on "الرسائل"
  };

  const handleHomeClick = () => {
    history('/dashboard'); // Navigate to "/dashboard" when clicking on "الرئيسية"
  };
  const handleMoared = () => {
    history('/moardeen'); // Navigate to "/dashboard" when clicking on "الرئيسية"
  };
  const handleOrders = () => {
    history('/orders'); // Navigate to "/dashboard" when clicking on "الرئيسية"
  };

  const handleHomeClickorders = () => {
    history('/'); // Navigate to "/dashboard" when clicking on "الرئيسية"
  };

  return (
    <AppBar style={{ textAlign: "right", direction: "rtl" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img src={image} alt='image' style={{ height: "90px", width: "150px" }} />
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            {/* Menu Icon for mobile view */}
          </Box>
          <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            LOGO
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {/* Page Buttons for desktop view */}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
           {settings.map((setting) => (
  <MenuItem key={setting} onClick={() => {
    if (setting === 'الرسائل') {
      handleMessagesClick();
    } else if (setting === 'تسجيل خروج') {
      handleSignOut();
    } else if (setting === 'الرئيسية') {
      handleHomeClick();
    } else if (setting === 'جوستوك') {
      handleHomeClickorders();
    }  else if (setting === 'الموردين') {
      handleMoared();
    } else if (setting === 'الطلبات') {
      handleOrders()
    }
      else {
      // Handle other cases if needed
    }
  }}>
    <Typography textAlign="center">{setting}</Typography>
  </MenuItem>
))}


            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default HeaderAdmin;
