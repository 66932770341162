import React from 'react';
import Slider from 'infinite-react-carousel';
import image1 from "../../images/1.png";
import image2 from "../../images/2.png";
import image3 from "../../images/3.png";
import image4 from "../../images/4.png";
import image5 from "../../images/10.png";
import image6 from "../../images/11.png";
import image7 from "../../images/12.png";
import image8 from "../../images/13.png";
import image9 from "../../images/14.png";
import image10 from "../../images/15.png";
import image11 from "../../images/16.png";
import image12 from "../../images/17.png";
import './Partners.css'; // Import your CSS file for additional styles
import { ModeContext } from '../ModeContext/ModeContext';
import { useContext } from 'react';

const Partners = () => {
  const { modeLabel } = useContext(ModeContext);
  const slidesToShow = window.innerWidth <= 700 ? 3 : 6;
  return(
    <div className="partners-container" style={{backgroundColor: modeLabel === 'dark mode' ? '#1e1e1e' : '#e9e9e9',
    marginTop: modeLabel === 'dark mode' ? '40px' : '30px'}}>
    <h1 className='labelMazan' style={{fontFamily: "Changa , sans-serif",color: modeLabel === 'dark mode' ? 'white' : 'black'}}>
      شركـائـنا
    </h1>
    <Slider
      arrows={false}
      dots={false}
      duration={1000}
      autoplay
      slidesToShow={slidesToShow} // Adjust the number of visible slides as needed
      className="custom-slider"
    >
      <div className="partner-slide">
        <img src={image1} alt='imag1' />
      </div>
      <div className="partner-slide">
        <img src={image2} alt='imag2' />
      </div>
      <div className="partner-slide">
        <img src={image3} alt='imag3' />
      </div>
      <div className="partner-slide">
        <img src={image4} alt='imag4' />
      </div>
      <div className="partner-slide">
        <img src={image5} alt='imag5' />
      </div>
      <div className="partner-slide">
        <img src={image6} alt='imag6' />
      </div>
      <div className="partner-slide">
        <img src={image7} alt='imag7' />
      </div>
      <div className="partner-slide">
        <img src={image8} alt='imag8' />
      </div>
      <div className="partner-slide">
        <img src={image9} alt='imag9' />
      </div>
      <div className="partner-slide">
        <img src={image10} alt='imag10' />
      </div>
      <div className="partner-slide">
        <img src={image11} alt='imag11' />
      </div>
      <div className="partner-slide">
        <img src={image12} alt='imag12' />
      </div>
    </Slider>
  </div>
  )
}

export default Partners;
