import React from 'react';
import { useForm } from 'react-hook-form';
import { TextField, Button, Typography, Container, Box } from '@mui/material';
import Header from '../../components/Hedaer.jsx/Header';
import {Link, useNavigate} from "react-router-dom"
import { ModeContext } from '../../components/ModeContext/ModeContext';
import { useContext } from 'react';
import Swal from "sweetalert2"
const LoginForm = () => {
  const { modeLabel } = useContext(ModeContext);
    const Navigate = useNavigate()

  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  
  const handleFormSubmit = async (data) => {
    const username = data.username;
    const password = data.password;

    try {
      const formData = new FormData();
      formData.append('username', username);
      formData.append('password', password);

      const response = await fetch('https://gostookbackend.gostook.com/checkuserAliExpress.php', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const responseData = await response.json();

        if (responseData.status === 'success') {
          localStorage.setItem('userData', JSON.stringify(responseData.user));
          localStorage.setItem('isUser', responseData.token);
          Navigate('/dashboarduser')
        } else {
          console.error('API Error:', responseData.message);
          Swal.fire({
            icon: "error",
            title: "خطأ...",
            text: 'هناك خطأ تأكد من كلمة المرور واسم المستخدم',
          });
        }
      } else {
        console.error('Failed to send data to the API');
        Swal.fire({
          icon: "error",
          title: "خطأ...",
          text: 'Failed to send data to the API',
        });
      }
    } catch (error) {
      console.error('Error while sending data to the API:', error);
      Swal.fire({
        icon: "error",
        title: "خطأ...",
        text: 'Failed to send data to the API',
      });
    }
  };

  return (
    <div style={{backgroundColor: modeLabel === 'dark mode' ? '#121212' : 'white' }}>
      <Header />
      <Container
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '81.6vh',
        }}
      >
        <Box
          style={{
            width: '100%',
            maxWidth: '90%',
            padding: '20px',
            border: '1px solid #ccc',
            borderRadius: '5px',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
            backgroundColor: modeLabel === 'dark mode' ? 'black' : 'white' 
          }}
        >
          <Typography style={{fontFamily:"Cairo",color: modeLabel === 'dark mode' ? 'white' : '#333' }} variant="h4" component="h1" align="center" gutterBottom>
            دخـــول
          </Typography>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <TextField
              label="اسم المستخدم"
              variant="outlined"
              fullWidth
              {...register('username', { required: 'Email is required' })}
              style={{ marginBottom: '15px',direction:"rtl" }}
              error={!!errors.username}
              helperText={errors.username?.message}
            />
            <TextField
              type="password"
              label="كلمة المرور"
              variant="outlined"
              fullWidth
              {...register('password', { required: 'Password is required' })}
              style={{ marginBottom: '15px',direction:"rtl" }}
              error={!!errors.password}
              helperText={errors.password?.message}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              style={{ marginTop: '20px',fontFamily:"Cairo",fontSize:"25px" }}
            >
              دخـــول
            </Button>
          </form>
          <Link to="/signup" style={{textAlign:"center",textDecoration:"none"}}>
          <p style={{fontFamily:"Cairo",fontSize:"20px"}}> !سجل معنا  </p>
        </Link>
        </Box>
      </Container>
    </div>
  );
};

export default LoginForm;
