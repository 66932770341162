import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './ContactsTable.css'; // Import CSS file for styling
import { useNavigate } from "react-router-dom";
import Header from '../../components/Hedaer.jsx/Header';
import HeaderAdmin from '../../components/dashboard/Header';
const ContactsTable = () => {
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await axios.get('https://gostookbackend.gostook.com/getcontacts.php');
        setContacts(response.data);
      } catch (error) {
        console.error('Error fetching contacts:', error);
      }
    };

    fetchContacts();
  }, []);

  const history = useNavigate();

  useEffect(() => {
    // Check if adminToken exists in local storage
    const adminToken = localStorage.getItem("adminToken");
    if (!adminToken || adminToken.trim() === "") {
      // Navigate to "/admin" if adminToken is not present or empty
      history("/admin");
    }
  }, [history]);

  return (
    <div>
        <HeaderAdmin/>
    <div className="contacts-container">
      <h1>جدول الاتصالات</h1>
      <table className="contacts-table">
        <thead>
          <tr>
            <th>الاسم</th>
            <th>البريد الإلكتروني</th>
            <th>الرسالة</th>
          </tr>
        </thead>
        <tbody>
          {contacts.map((contact) => (
            <tr key={contact.id}>
              <td>{contact.name}</td>
              <td>{contact.email}</td>
              <td>{contact.message}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </div>
  );
};

export default ContactsTable;
