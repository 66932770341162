import React, { useState, useEffect, useRef, useContext } from "react";
import ResponsiveAppBar from "../../components/Navbar/Navbar";
import "./productspage.css";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Link } from "react-router-dom";
import { ModeContext } from "../../components/ModeContext/ModeContext";
import CircularProgressThickness from "../../components/MoreRequests/Spinner";
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const ProductsPage = () => {
    const [products, setProducts] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(true);
    const { modeLabel } = useContext(ModeContext);
    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 50;
    const paginationSize = 5;
    const [categories, setCategories] = useState([]);
    const categoriesPerPage = 5;
    const categoriesContainerRef = useRef(null);
    const [startCategoryIndex, setStartCategoryIndex] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [progress, setProgress] = useState(0);

    const excludedCategories = ["ah09", "ab10", "as11", "da03", "wl07"];
    const excludedCategories2 = ["am06", "eb02", "gh08", "hn01", "kh05","mo04"];
    const [filterToCategoryMap, setFilterToCategoryMap] = useState({});

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch(
                    `https://goostook.com/wp-json/wc/v3/products/categories?consumer_key=ck_198703302120ef330806f9d5d1b0d5e7cd032c08&consumer_secret=cs_96230e77a2a2eddf102682ae70c993924a7a6821&per_page=100`
                );
                const data = await response.json();
                const filteredCategories = data.filter(category => !excludedCategories.includes(category.slug));
                const categoryData = filteredCategories.map(category => ({ name: category.name, id: category.id }));
                setCategories(categoryData);

                const filterMap = {
                    "منتجات موردينا": 1, // placeholder, update with actual ID
                    "منتجات الصين": 5090 // placeholder, update with actual ID
                };

                categoryData.forEach(category => {
                    if (!filterMap[category.name]) {
                        filterMap[category.name] = category.id;
                    }
                });

                setFilterToCategoryMap(filterMap);
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        };

        fetchCategories();
    }, []);

    const fetchProducts = async (page, categoryId = null) => {
        try {
            let url = `https://goostook.com/wp-json/wc/v3/products?consumer_key=ck_198703302120ef330806f9d5d1b0d5e7cd032c08&consumer_secret=cs_96230e77a2a2eddf102682ae70c993924a7a6821&per_page=${productsPerPage}&page=${page}`;
            if (categoryId) {
                url += `&category=${categoryId}`;
            }
            const response = await fetch(url);
            const data = await response.json();
            return data;
        } catch (error) {
            console.error("Error fetching data:", error);
            return [];
        }
    };

    const fetchAllProductsFromCategory = async (categoryId) => {
        let allProducts = [];
        let currentPage = 1;
        while (true) {
            const newProducts = await fetchProducts(currentPage, categoryId);
            if (newProducts.length === 0) {
                break;
            }
            allProducts = [...allProducts, ...newProducts];
            currentPage++;
        }
        return allProducts;
    };

    useEffect(() => {
        const loadInitialProducts = async () => {
            setProgress(0);
            const initialProducts = await fetchProducts(1);
            setProducts(initialProducts);
            setLoading(false);
            setProgress(100);
        };

        loadInitialProducts();
    }, []);

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1);
    };

    const handleCategoryClick = async (category) => {
        setLoading(true);
        const categoryId = category.id;
        const categoryProducts = await fetchAllProductsFromCategory(categoryId);
        setProducts(categoryProducts);
        setSelectedCategory(category.name === selectedCategory ? null : category);
        setLoading(false);
        setCurrentPage(1);
    };

    const handleClearFilters = async () => {
        setLoading(true);
        setSelectedCategory(null);
        setSelectedFilter(null);
        setSearchQuery("");
        const defaultProducts = await fetchProducts(1);
        setProducts(defaultProducts);
        setLoading(false);
        setCurrentPage(1);
    };

    const filters = ["gh08", "am06", "kh05", "mo04", "eb02", "hn01"];

    const handleFilterClick = async (filter) => {
        if (selectedFilter === filter) {
            setSelectedFilter(null); // Deselect the filter if it's already selected
        } else {
            setSelectedFilter(filter); // Select the new filter
        }

        // Fetch products based on the filter
        if (filterToCategoryMap[filter]) {
            const categoryId = filterToCategoryMap[filter];
            const category = categories.find(cat => cat.id === categoryId);
            if (category) {
                await handleCategoryClick(category);
            }
        } else {
            // If no filter is selected, fetch all products
            await handleClearFilters();
        }
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const filteredProducts = products.filter((product) => {
        const nameMatches = product.name.toLowerCase().includes(searchQuery.toLowerCase());
        const categoryMatches = !selectedCategory || selectedCategory.name === "الكل" || (product.categories && product.categories.some((category) => category.name.includes(selectedCategory.name)));

        let skuMatches = true;
        if (selectedFilter === "منتجات موردينا") {
            const supplierSKURegex = /^[a-zA-Z]{2,}\d{2,}_\d+(_\d+)?$/;
            skuMatches = supplierSKURegex.test(product.sku);
        } else if (selectedFilter === "منتجات الصين") {
            const supplierSKURegex = /^[a-zA-Z]{2,}\d{2,}_\d+(_\d+)?$/;
            skuMatches = !supplierSKURegex.test(product.sku);
        } else if (selectedFilter) {
            skuMatches = product.sku.substring(0, 4) === selectedFilter.substring(0, 4);
        }

        return nameMatches && categoryMatches && skuMatches;
    });

    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

    const totalPages = Math.ceil(filteredProducts.length / productsPerPage);
    const getPaginationGroup = () => {
        const start = Math.floor((currentPage - 1) / paginationSize) * paginationSize;
        return new Array(Math.min(paginationSize, totalPages - start)).fill().map((_, idx) => start + idx + 1);
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const scrollLeft = () => {
        const newIndex = startCategoryIndex - categoriesPerPage;
        setStartCategoryIndex(Math.max(0, newIndex));
    };

    const scrollRight = () => {
        const newIndex = startCategoryIndex + categoriesPerPage;
        setStartCategoryIndex(Math.min(categories.length - categoriesPerPage, newIndex));
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const handleArrowClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setOpenSnackbar(false);
    };

    return (
        <div className="ParentDiv" style={{
            backgroundColor: modeLabel === "dark mode" ? "#1e1e1e" : "#f5f5f5",
        }}>
            {loading ? (
                <div style={{ display: "flex", justifyContent: "center", textAlign: "center", alignItems: "center",
                    height: "100vh"}}>
                    <CircularProgressThickness />
                </div>
            ) : (
                <>
                    <ResponsiveAppBar />
                    <div className="divtitle">
                        <h1 style={{
                            color: modeLabel === "dark mode" ? "white" : "black",
                        }} className="label-mazan45">المنتجات</h1>
                    </div>
                    <div className="feaat">
                        <div className="examples">
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "20px" }}>
                                <IconButton onClick={scrollLeft} sx={{ p: '10px' }} aria-label="scroll left">
                                    <ArrowBackIcon style={{
                                        color: modeLabel === "dark mode" ? "white" : "black",
                                    }} />
                                </IconButton>
                                <div className="categories-container" ref={categoriesContainerRef}>
                                    {categories
                                        .filter(category => !excludedCategories2.includes(category.name))
                                        .slice(startCategoryIndex, startCategoryIndex + categoriesPerPage)
                                        .map((category, index) => (
                                            <Button
                                                key={index}
                                                style={{
                                                    backgroundColor: selectedCategory && selectedCategory.name === category.name ? "#fadb81" : "#ccc",
                                                    color: "black",
                                                    fontFamily: "Cairo",
                                                    margin: "3px"
                                                }}
                                                variant="contained"
                                                onClick={() => handleCategoryClick(category)}
                                            >
                                                {category.name}
                                            </Button>
                                        ))}
                                </div>
                                <IconButton onClick={scrollRight} sx={{ p: '10px' }} aria-label="scroll right">
                                    <ArrowForwardIcon style={{
                                        color: modeLabel === "dark mode" ? "white" : "black",
                                    }} />
                                </IconButton>
                                <Button
                                    style={{ backgroundColor: "red", color: "white", fontFamily: "Cairo", margin: "3px" }}
                                    variant="contained"
                                    onClick={handleClearFilters}
                                >
                                    حذف التصفية
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div style={{ textAlign: "center" }} className="filter-buttons">
                        {/* <Button
                            style={{ backgroundColor: selectedFilter === "منتجات موردينا" ? "#fadb81" : "#ccc", color: "black", fontFamily: "Cairo", margin: "3px" }}
                            variant="contained"
                            onClick={() => handleFilterClick("منتجات موردينا")}
                        >
                            منتجات موردينا
                        </Button> */}
                        <Button
                            style={{ backgroundColor: selectedFilter === "منتجات الصين" ? "#fadb81" : "#ccc", color: "black", fontFamily: "Cairo", margin: "3px" }}
                            variant="contained"
                            onClick={() => handleFilterClick("منتجات الصين")}
                        >
                            منتجات الصين
                        </Button>
                    </div>
                    <br />
                    <div style={{ display: "flex", justifyContent: "center" }} className="filter-buttons">
                        <IconButton
                            style={{ backgroundColor: "#ccc", color: "black", fontFamily: "Cairo", margin: "3px" }}
                            onClick={handleMenuClick}
                        >
                            <FilterListIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            {filters.map((filter, index) => (
                                <MenuItem
                                    key={index}
                                    onClick={() => {
                                        handleFilterClick(filter);
                                        handleMenuClose();
                                    }}
                                    style={{ backgroundColor: selectedFilter === filter ? "#fadb81" : "transparent" }}
                                >
                                    {filter}
                                </MenuItem>
                            ))}
                        </Menu>
                    </div>
                    <br />
                    <div className="search-container">
                        <Paper component="form" className="search-bar">
                            <InputBase
                                value={searchQuery}
                                onChange={handleSearch}
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="البحث عن منتج"
                                inputProps={{ 'aria-label': 'البحث عن منتج' }}
                            />
                            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                <SearchIcon />
                            </IconButton>
                        </Paper>
                    </div>
                    <br />
                    <br />
                    <br />
                    <hr style={{
                        height: "2px",
                        border: "1px solid #1b7d4f ",
                        backgroundColor: "#1b7d4f",
                        width: "80%",
                        margin: "auto"
                    }} />
                    <div style={{
                        backgroundColor: modeLabel === "dark mode" ? "#1e1e1e" : "#f5f5f5",
                        paddingBottom: "70px"
                    }}>
                        <div className="my-5 containerr" style={{ marginTop: "50px", textAlign: "center" }}>
                            {currentProducts.length > 0 ? (
                                currentProducts.map((product, index) => (
                                    <div className="card" key={index} style={{
                                        backgroundColor: modeLabel === "dark mode" ? "#1e1e1e" : "white",
                                    }}>
                                        <img style={{ height: "380px" }} src={product.images && product.images.length > 0 ? product.images[0].src : ""} alt={product.name} />
                                        <div className="card-body">
                                            <h3 style={{
                                                fontFamily: "Cairo",
                                                color: modeLabel === "dark mode" ? "white" : "black",
                                            }}>{product.name.substring(0, 20)}</h3>
                                            <p style={{
                                                color: modeLabel === "dark mode" ? "white" : "black",
                                            }} className="text-muted">
                                                {product.categories && product.categories.length > 0 ? product.categories.map(category => category.name.substring(0, 20)).join(", ") : "غير مصنف"}
                                            </p>
                                            <p style={{
                                                color: modeLabel === "dark mode" ? "white" : "black",
                                            }}>{product.sku}</p>
                                            <Link style={{ textDecoration: "none", color: "white" }} to={`/product/${product.id}`} className="custom-button">اقرء المزيد</Link>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p style={{
                                    fontFamily: "Cairo",
                                    color: modeLabel === "dark mode" ? "white" : "black",
                                }}>لا توجد منتجات</p>
                            )}
                        </div>
                        <ul className="pagination">
                            <li className="page-item">
                                <button className="page-link" onClick={prevPage} disabled={currentPage === 1}>
                                    &laquo;
                                </button>
                            </li>
                            {getPaginationGroup().map((item, index) => (
                                <li key={index} className={`page-item ${currentPage === item ? "active" : ""}`}>
                                    <button onClick={() => paginate(item)} className="page-link">
                                        {item}
                                    </button>
                                </li>
                            ))}
                            <li className="page-item">
                                <button className="page-link" onClick={nextPage} disabled={currentPage === totalPages}>
                                    &raquo;
                                </button>
                            </li>
                        </ul>
                    </div>
                    <style jsx>{`
                        .pagination {
                            display: flex;
                            list-style-type: none;
                            justify-content: center;
                            margin-top: 20px;
                        }

                        .page-item {
                            margin: 0 8px; /* Increased margin for larger spacing */
                        }

                        .page-link {
                            padding: 10px 15px; /* Increased padding for larger buttons */
                            border: 2px solid #ccc; /* Thicker border for better visibility */
                            border-radius: 10px; /* Rounded corners */
                            background-color: #fff;
                            color: #333;
                            cursor: pointer;
                            transition: background-color 0.3s ease;
                            font-size: 18px; /* Larger font size */
                        }

                        .page-link:hover {
                            background-color: #f5f5f5;
                        }

                        .active .page-link {
                            background-color: #1b7d4f;
                            color: #fff;
                            border-color: #1b7d4f;
                        }

                        .search-container {
                            display: flex;
                            justify-content: center;
                            margin-bottom: 20px;
                        }

                        .search-bar {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 400px; /* Adjust the width as needed */
                            padding: 2px 4px;
                            margin: auto;
                        }
                    `}</style>
                </>
            )}
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <MuiAlert
                    onClose={handleSnackbarClose}
                    severity="info"
                    sx={{ width: '100%', backgroundColor: '#ff9800', color: 'black', fontSize: '18px', fontFamily: "Cairo" }}
                    action={
                        <IconButton
                            aria-label="scroll down"
                            color="inherit"
                            size="small"
                            onClick={handleArrowClick}
                        >
                            <ArrowDownwardIcon style={{border:"3px solid #1b7d4f",borderRadius:"50%",fontSize:"30px"}} />
                        </IconButton>
                    }
                >
                    يجب عليك تحميل جميع المنتجات لاستخدام الفلتر بشكل صحيح.من خلال الضغط على زر تحميل كل المنتجات
                </MuiAlert>
            </Snackbar>
        </div>
    );
};

export default ProductsPage;
