import React from 'react';
import Slider from 'react-infinite-logo-slider';

const JIF = () => {
    const sentences = [
        'منتجات مميزة',
        'جودة عالية',
        'تعاون ودعم',
        'خيارات متنوعة',
        'دعم اقتصادي',
        'استدامة محلية',
        'شراكات ناجحة',
        'جذب العملاء',
    ];

    return (
        <div style={{ marginTop: "50px",backgroundColor:"#b5b5b5" }}>
            <Slider
                width="250px"
                duration={40}
                pauseOnHover={true}
                blurBorders={false}
                blurBorderColor={'#fff'} // Corrected spelling
                style={{ backgroundColor: "black" }}
            >
                {sentences.map((sentence, index) => (
                    <Slider.Slide key={index}>
                        <h3 style={{fontFamily:"cairo",color:"white"}}>{sentence}</h3>
                    </Slider.Slide>
                ))}
            </Slider>
        </div>
    );
};

export default JIF;
