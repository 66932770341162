import React from "react";
import "./Banner2.css";
import imag1 from "../images/manzlya1.png";
import imag2 from "../images/manzlya2.png";
import imag3 from "../images/manzlya3.png";
import Fade from "react-reveal/Fade";

const Banner2 = () => {
  return (
    <div className="banner" style={{ marginTop: "70px" }}>
      <div className="SliderAnimation">
        <Fade top duration={1500}>
          <div>
            <img src={imag3} className="image11" alt="Image 1" />
          </div>
        </Fade>
        <Fade bottom delay={500} duration={1500}>
          <div>
            <img src={imag1} className="image33" alt="Image 2" />
          </div>
        </Fade>
        <Fade top delay={1000} duration={2000}>
          <div>
            <img src={imag2} className="image22" alt="Image 3" />
          </div>
        </Fade>
      </div>
      <div className="TextAnimation">
        <h2
          className="animated-text text1"
        >
          أحدث الأجـهـزة
        </h2>
        <h2
          className="animated-text text2"
        >
          MODERN ELECTRONICS
        </h2>
      </div>
    </div>
  );
};

export default Banner2;
