import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HeaderAdmin from "./Header";
import CustomizedTables from "./TableForUsers";

const HomeAdmin = () => {
  const history = useNavigate();

  useEffect(() => {
    // Check if adminToken exists in local storage
    const adminToken = localStorage.getItem("adminToken");
    if (!adminToken || adminToken.trim() === "") {
      // Navigate to "/admin" if adminToken is not present or empty
      history("/admin");
    }
  }, [history]);

  return (
    <>
      <HeaderAdmin />
      <CustomizedTables />
    </>
  );
};

export default HomeAdmin;
