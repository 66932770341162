import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MenuAppBar from '../../components/HeaderDashboardUser/HeaderDashboardUser';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Swal from 'sweetalert2';


const ProductTable = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalProducts, setTotalProducts] = useState(0);
  const productsPerPage = 20; // Changed to 20 products per page
  const [pageGroup, setPageGroup] = useState(1);
  const pagesPerGroup = 5;

  const navigate = useNavigate();
  const [dataOfUser, setDataOfUser] = useState([]);

  useEffect(() => {
    const adminToken = localStorage.getItem('isUser');
    const userData = localStorage.getItem("userData");
    setDataOfUser(JSON.parse(userData));

    if (!adminToken || adminToken.trim() === '') {
      navigate('/login');
    }
  }, [navigate]);

  const userDataJSON = localStorage.getItem('userData');
  const userData = userDataJSON ? JSON.parse(userDataJSON) : null;
  const { url, consumerKey, secretKey } = userData || {};

  const fetchProducts = async (baseUrl, page = 1, perPage = productsPerPage) => {
    const response = await fetch(
      `${baseUrl}&per_page=${perPage}&page=${page}`
    );
    if (!response.ok) {
      throw new Error('Failed to fetch data from API');
    }
    const data = await response.json();
    const totalCount = response.headers.get('X-WP-Total');
    return { data, totalCount: parseInt(totalCount, 10) };
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data, totalCount } = await fetchProducts(
          `${url}/wp-json/wc/v3/products?consumer_key=${consumerKey}&consumer_secret=${secretKey}`,
          currentPage,
          productsPerPage
        );
        setProducts(data);
        setTotalProducts(totalCount);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message || 'An error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url, consumerKey, secretKey, currentPage, productsPerPage]);

  const totalPages = Math.ceil(totalProducts / productsPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    const newPageGroup = Math.ceil(pageNumber / pagesPerGroup);
    setPageGroup(newPageGroup);
  };

  const validateSKU = (sku) => {
    const regex = /^[a-zA-Z]{2,}\d{2,}_\d+(_\d+)?$/;
    return regex.test(sku);
  };

  const deleteLocalProduct = async (productId) => {
    try {
      const deleteResponse = await fetch(
        `${url}/wp-json/wc/v3/products/${productId}?consumer_key=${consumerKey}&consumer_secret=${secretKey}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (!deleteResponse.ok) {
        throw new Error('Failed to delete product from local store');
      }
      setProducts((prevProducts) => prevProducts.filter(product => product.id !== productId));
      Swal.fire('Deleted', 'تم حذف بعض المنتجات لم تعد موجودة في المنصة خاصتنا', 'success');
    } catch (error) {
      console.error('Error deleting product:', error);
      Swal.fire('Error', `Failed to delete product: ${error.message}`, 'error');
    }
  };

  const handleSync = async (product) => {
    try {
      Swal.fire({
        title: 'مطابقة المنتج',
        html: 'يتم الأن مطــابقة منتجك مع جوستوك',
        icon: 'info',
        didOpen: () => {
          Swal.showLoading();
        },
      });

      if (validateSKU(product.sku)) {
        try {
          const rootResponse = await fetch(
            `https://goostook.com/wp-json/wc/v3/products?consumer_key=ck_198703302120ef330806f9d5d1b0d5e7cd032c08&consumer_secret=cs_96230e77a2a2eddf102682ae70c993924a7a6821&sku=${product.sku}`
          );
          if (!rootResponse.ok) {
            throw new Error('Failed to fetch data from root API');
          }

          const rootData = await rootResponse.json();
          const rootProduct = rootData.find((p) => p.sku === product.sku);

          if (rootProduct) {
            const needsUpdate = rootProduct.stock_quantity !== product.stock_quantity;

            if (needsUpdate) {
              const updatedData = {
                stock_quantity: rootProduct.stock_quantity,
              };

              const updateResponse = await fetch(
                `${url}/wp-json/wc/v3/products/${product.id}?consumer_key=${consumerKey}&consumer_secret=${secretKey}`,
                {
                  method: 'PUT',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(updatedData),
                }
              );

              if (!updateResponse.ok) {
                throw new Error('Failed to update product on root API');
              }

              const updateData = await updateResponse.json();
              Swal.fire("Success", "Product updated successfully!", "success");
              console.log('Product updated successfully:', updateData);
            } else {
              console.log("No Update Needed", "Stock quantity is already up to date", "info");
            }
          } else {
            await deleteLocalProduct(product.id);
          }
        } catch (error) {
          Swal.fire("Error", error.message, "error");
          console.error(error);
        }
      }

      const rootResponse = await fetch(
        `https://goostook.com/wp-json/wc/v3/products?consumer_key=ck_198703302120ef330806f9d5d1b0d5e7cd032c08&consumer_secret=cs_96230e77a2a2eddf102682ae70c993924a7a6821&sku=${product.sku}`
      );
      if (!rootResponse.ok) {
        throw new Error('Failed to fetch data from root API');
      }
      const rootData = await rootResponse.json();
      const rootProduct = rootData.find((p) => p.sku === product.sku);

      if (rootProduct) {
        const localVariationsResponse = await fetch(
          `${url}/wp-json/wc/v3/products/${product.id}/variations?consumer_key=${consumerKey}&consumer_secret=${secretKey}&per_page=100`
        );
        if (!localVariationsResponse.ok) {
          throw new Error('Failed to fetch variations from local API');
        }
        const localVariationsData = await localVariationsResponse.json();

        const rootVariationsResponse = await fetch(
          `https://goostook.com/wp-json/wc/v3/products/${rootProduct.id}/variations?consumer_key=ck_198703302120ef330806f9d5d1b0d5e7cd032c08&consumer_secret=cs_96230e77a2a2eddf102682ae70c993924a7a6821&per_page=100`
        );
        if (!rootVariationsResponse.ok) {
          throw new Error('Failed to fetch variations from root API');
        }
        const rootVariationsData = await rootVariationsResponse.json();

        for (const localVariation of localVariationsData) {
          const matchedRootVariation = rootVariationsData.find((rootVariation) => rootVariation.sku === localVariation.sku);
          if (matchedRootVariation) {
            if (
              localVariation.stock_quantity !== matchedRootVariation.stock_quantity ||
              localVariation.stock_status !== matchedRootVariation.stock_status
            ) {
              Swal.fire({
                title: 'تحديث المتغيرات',
                html: `تم تحديث المتغيرات للمنتج: ${product.name} - <span style="color: red;">${localVariation.name}</span>`,
                icon: 'info',
                showConfirmButton: false,
              });

              await updateVariation(product, localVariation, matchedRootVariation);
            }
            Swal.fire({
              title: 'تمت المطابقة',
              html: `تم تحديث المتغيرات للمنتج: ${product.name} - <span style="color: red;">${localVariation.name}</span>`,
              icon: 'success',
              showConfirmButton: false,
            });
          }
        }

        Swal.fire({
          title: 'تمت المطابقة',
          text: 'تم تحديث المتغيرات بنجاح',
          icon: 'success',
          showConfirmButton: true,
        });
      } else {
        await deleteLocalProduct(product.id);
        Swal.fire({
          title: 'Error',
          text: 'No matching product found in Root API. Product removed from local store.',
          icon: 'error',
          showConfirmButton: true,
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: `An error occurred: ${error.message}`,
        icon: 'error',
        showConfirmButton: true,
      });
      console.error('Error handling sync:', error);
    }
  };

  const updateVariation = async (product, localVariation, matchedRootVariation) => {
    try {
      const updateResponse = await fetch(
        `${url}/wp-json/wc/v3/products/${product.id}/variations/${localVariation.id}?consumer_key=${consumerKey}&consumer_secret=${secretKey}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            stock_quantity: matchedRootVariation.stock_quantity,
            stock_status: matchedRootVariation.stock_status,
            price: matchedRootVariation.price,
            regular_price: matchedRootVariation.regular_price,
          }),
        }
      );
      if (!updateResponse.ok) {
        const responseData = await updateResponse.json();
        console.error('Error updating variation:', responseData);
        throw new Error('Failed to update variation in local API');
      }
      console.log('Variation updated in local API:', localVariation.id);
      console.log('Updated variation:', localVariation);
    } catch (error) {
      console.error('Error updating variation:', error);
      throw new Error(`Error updating variation: ${error.message}`);
    }
  };

  const handleSyncAll = async () => {
    try {
      Swal.fire({
        title: 'مطابقة كل المنتجات',
        text: 'يتم الآن مطابقة جميع المنتجات مع جوستوك',
        icon: 'info',
        showConfirmButton: false,
        allowOutsideClick: false,
      });

      await Promise.all(products.map(async (product) => {
        await handleSync(product);
      }));

      Swal.close();
      Swal.fire({
        title: 'تمت المطابقة',
        text: 'تم تحديث جميع المنتجات مع جوستوك بنجاح',
        icon: 'success',
        showConfirmButton: true,
      });
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: `An error occurred: ${error.message}`,
        icon: 'error',
        showConfirmButton: true,
      });
      console.error('Error handling sync for all products:', error);
    }
  };

  if (error) {
    return (
      <div style={{ textAlign: 'center', marginTop: '50px' }}>
        <Typography variant="body1" color="error">
          {error}
        </Typography>
        <Button variant="contained" onClick={() => window.location.reload()}>
          إعادة المحاولة
        </Button>
      </div>
    );
  }

  return (
    <div>
      <MenuAppBar />
      <h1 style={{ backgroundColor: "#71cc6d", textAlign: "center" }}>
        <span style={{ color: "#1976d2" }}>
          {dataOfUser.firstname} {dataOfUser.lastname}
        </span>{" "}
        أهلا بكم
      </h1>
      <h3 style={{ fontFamily: 'Cairo', fontSize: '20px', textAlign: 'center' }}>
        المنتجات التي قمت باستيرادها في متجرك
      </h3>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      ) : products.length === 0 ? (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
          <h3 style={{ fontFamily: "Cairo", fontSize: "15px" }}>
            لا يوجد منتجات، استورد منتجاتك الآن
          </h3>
          <Link to="/products">
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: '20px', fontFamily: 'Cairo', fontSize: "25px" }}
            >
              استيراد منتجات
            </Button>
          </Link>
        </div>
      ) : (
        <>
          <Paper style={{ margin: '20px', padding: '20px' }}>
            <Table style={{ direction: 'rtl' }}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '35%', textAlign: 'center', fontFamily: 'Cairo' }}>
                    الاسم
                  </TableCell>
                  <TableCell style={{ width: '15%', textAlign: 'center', fontFamily: 'Cairo' }}>السعر</TableCell>
                  <TableCell style={{ width: '25%', textAlign: 'center', fontFamily: 'Cairo' }}>الصورة</TableCell>
                  <TableCell style={{ width: '15%', textAlign: 'center', fontFamily: 'Cairo' }}>تعديل</TableCell>
                  <TableCell style={{ width: '20%', textAlign: 'center', fontFamily: 'Cairo' }}>مطابقة المنتج مع جوستوك</TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ textAlign: 'center' }}>
                {products.map((product) => (
                  <TableRow key={product.id}>
                    <TableCell style={{ textAlign: 'right', fontFamily: 'Cairo' }}>{product.name}</TableCell>
                    <TableCell style={{ textAlign: 'center', fontFamily: 'Cairo' }}>{product.price} SAR</TableCell>
                    <TableCell style={{ textAlign: 'center', fontFamily: 'Cairo' }}>
                      <img src={product.images[0].src} alt={product.name} style={{ width: 100 }} />
                    </TableCell>
                    <TableCell style={{ textAlign: 'center', fontFamily: 'Cairo' }}>
                      <Link to={`${url}/wp-admin/post.php?post=${product.id}&action=edit`} target="_blank">
                        <Button variant="contained" color="primary" style={{ fontFamily: 'Cairo' }}>
                          تعديل
                        </Button>
                      </Link>
                    </TableCell>
                    <TableCell style={{ textAlign: 'center', fontFamily: 'Cairo' }}>
                      <Button onClick={() => handleSync(product)} variant="contained" color="primary" style={{ fontFamily: 'Cairo' }}>
                        مطابقة
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
          <div style={{ textAlign: "center" }}>
            <Button onClick={handleSyncAll} variant="contained" color="primary" style={{ fontFamily: 'Cairo' }}>
              مطابقة كل المنتجات
            </Button>
          </div>
        </>
      )}

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        {pageGroup > 1 && (
          <Button onClick={() => setPageGroup(pageGroup - 1)} style={{ margin: '2px' }}>
            {'<'}
          </Button>
        )}
        {[...Array(Math.min(pagesPerGroup, totalPages - (pageGroup - 1) * pagesPerGroup))].map((_, index) => (
          <Button
            key={index}
            onClick={() => paginate((pageGroup - 1) * pagesPerGroup + index + 1)}
            style={{ margin: '2px', fontWeight: currentPage === (pageGroup - 1) * pagesPerGroup + index + 1 ? 'bold' : 'normal' }}
          >
            {(pageGroup - 1) * pagesPerGroup + index + 1}
          </Button>
        ))}
        {pageGroup < Math.ceil(totalPages / pagesPerGroup) && (
          <Button onClick={() => setPageGroup(pageGroup + 1)} style={{ margin: '2px' }}>
            {'>'}
          </Button>
        )}
      </div>
    </div>
  );
}

export default ProductTable;
