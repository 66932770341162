import React from 'react';
import './Footer.css'; // Import your CSS file for additional styles
import image from "../../images/جو-ستوك.png"
import image2 from "../../images/open-an-online-store.svg";
import { Link } from 'react-router-dom';

const Footer = () => (
  <footer className="footer-container">
    <div className="footer-inner">
      <div className="footer-column">
        <img style={{ width: "220px", height: "100px" }} src={image} alt='image' />
        <p className='mb-0 text-center text-white footer-ar'>
          تعد منصتنا الرائدة في دعم المتاجر الإلكترونية بواسطة تقديم دعم لوجستي متكامل.
          نحن نسعى دائمًا لتطوير
          الأعمال وتحسين المنتجات لتلبية احتياجات عملائنا
        </p>
      </div>
      <div className="footer-column">
        <img style={{ width: "150px", height: "150px" }} src={image2} alt='image' />
        <p className='footer-heading مخازن mb-2 mt-4 text-center text-white fw-bold p-md centerlize-content'>عليك المتجر
          <br />
          وعلينا المنتجات
        </p>
      </div>
      <div className="footer-column">
        <ul className='UlList'>
          <Link style={{textDecoration:"none"}} to="/">
          <li> الرئيسية</li>
          </Link>
          <Link style={{textDecoration:"none"}} to="/contactus">
          <li> استفسارك</li>
          </Link>
          <Link style={{textDecoration:"none"}} to="/products">
          <li> منتجاتنا</li>
          </Link>
          <Link style={{textDecoration:"none"}} to="/signup">
          <li> انضم معنا</li>
          </Link>
          <Link style={{textDecoration:"none"}} to="/dashboarduser">
          <li> حسابك</li>
          </Link>
        </ul>
      </div>
    </div>
  </footer>
);

export default Footer;
