import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MenuAppBar from "../HeaderDashboardUser/HeaderDashboardUser";
import OrdersTable from "../TableOrdersUserDashboard/TableOrdersUserDashboard";

const DashboardUser = () => {
  const history = useNavigate();

  useEffect(() => {
    // Check if adminToken exists in local storage
    const adminToken = localStorage.getItem("isUser");
    if (!adminToken || adminToken.trim() === "") {
      history("/login");
    }
  }, [history]);

  // Render the DashboardUser component
  return (
    <div>
      <MenuAppBar />
      <OrdersTable/>
    </div>
  );
};

export default DashboardUser;
