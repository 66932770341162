import React from "react";
import Header from "../../components/Hedaer.jsx/Header";
import Hello from "../../components/Hello/Hello";
import SimpleSlider from "../../components/Movingbar/Movingbar";
import ShowCircleTop from "../../components/ShowCircleTop/ShowCircleTop";
import MoreRequests from "../../components/MoreRequests/MoreRequests";
import ImportantNumbers from "../../components/ImportantNumbers/ImportantNumbers";
import Partners from "../../components/Partners/Partners";
import Footer from "../../components/Footer/Footer";
import { ModeContext } from "../../components/ModeContext/ModeContext";
import { useContext } from 'react';
import Banner1 from "../../components/Banner1";
import Banner2 from "../../components/Banner2";
import Banner3 from "../../components/Banner3";
import JIF from "../../components/Jifsliderstext";
const Home = ()=>{
    const { modeLabel } = useContext(ModeContext);
    return(
        <div style={{ backgroundColor: modeLabel === 'dark mode' ? '#121212' : '#e9e9e9'}} >
            <Header />
            <Hello />
            <Banner1/>
             <JIF/>
            <ShowCircleTop/>
            <SimpleSlider />
            <Banner2/>
            <MoreRequests/>
            <ImportantNumbers/>
            <Banner3/>
            <Partners />
            <Footer/>
        </div>
    )
}

export default Home