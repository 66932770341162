import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import Swal from 'sweetalert2';

const StoreOrdersTable = ({ store }) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [statusModified, setStatusModified] = useState(false);
  const [suppliersData, setSuppliersData] = useState([]);

  const fetchOrders = async () => {
    try {
      const response = await fetch(
        `${store.url}/wp-json/wc/v3/orders?consumer_key=${store.consumerKey}&consumer_secret=${store.secretKey}`
      );
      const data = await response.json();
      setOrders(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching orders:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [store]);

  useEffect(() => {
    const fetchSuppliersData = async () => {
      try {
        const response = await fetch('https://gostookbackend.gostook.com/getsupplyers.php');
        const data = await response.json();
        if (data.success) {
          setSuppliersData(data.suppliers);
        } else {
          console.error('Failed to fetch suppliers data');
        }
      } catch (error) {
        console.error('Error fetching suppliers data:', error);
      }
    };

    fetchSuppliersData();
  }, []);

  const updateOrderStatus = async () => {
    setLoading(true);
    try {
      if (!selectedOrder) {
        throw new Error('No order selected');
      }

      const response = await fetch(
        `${store.url}/wp-json/wc/v3/orders/${selectedOrder.id}?consumer_key=${store.consumerKey}&consumer_secret=${store.secretKey}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ status: selectedStatus }),
        }
      );
      setLoading(false);
      if (!response.ok) {
        setDialogOpen(false);
        throw new Error('Failed to update order status');
      }

      fetchOrders();
      setDialogOpen(false);
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'تم تحديث حالة طلبك',
        showConfirmButton: false,
        timer: 1500,
      });
      console.log('Order status updated successfully!');
    } catch (error) {
      setDialogOpen(false);
      console.error('Error updating order status:', error);
    }
  };

  const handleViewDetails = (order) => {
    setSelectedOrder(order);
    setSelectedStatus(order.status);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleStatusChange = (event) => {
    const newStatus = event.target.value;
    setSelectedStatus(newStatus);

    const isStatusModified = newStatus !== selectedOrder.status;
    setStatusModified(isStatusModified);
  };

  const handleOrderClick = (sku) => {
    const skuNumber = sku.split('-')[0];
    const url = `https://www.aliexpress.com/item/${skuNumber}.html`;
    window.open(url, '_blank');
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
    <div style={{border:"1px solid black",borderRadius:"50px",marginTop:"20px"}}>
      <h3 style={{ textAlign: 'center', fontFamily: 'Cairo' }}>{`${store.firstname} ${store.lastname}`}</h3>
      <h4 style={{ textAlign: 'center', fontFamily: 'Cairo' }}>{`${store.url}`}</h4>
      </div>
      <TableContainer style={{ width: '90%', margin: 'auto', marginTop: '50px', direction: 'rtl' }} component={Paper}>
        <Table style={{ minWidth: 650, width: '80%', margin: 'auto' }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: 'center', fontFamily: 'Cairo' }}>رقم الاوردر</TableCell>
              <TableCell style={{ textAlign: 'center', fontFamily: 'Cairo' }}>حالة الطلب</TableCell>
              <TableCell style={{ textAlign: 'center', fontFamily: 'Cairo' }}>اسم الفاتورة</TableCell>
              <TableCell style={{ textAlign: 'center', fontFamily: 'Cairo' }}>اسم الشحن</TableCell>
              <TableCell style={{ textAlign: 'center', fontFamily: 'Cairo' }}>الأفعال</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => (
              <TableRow key={order.id}>
                <TableCell style={{ textAlign: 'center', fontFamily: 'Cairo' }}>{order.id}</TableCell>
                <TableCell
                  style={{
                    textAlign: 'center',
                    fontFamily: 'Cairo',
                    color: '#1976d2',
                    fontSize: '20px',
                    backgroundColor: order.status === 'completed' ? '#c8d7e1' : '#c6e1c6',
                  }}
                >
                  {order.status}
                </TableCell>
                <TableCell style={{ textAlign: 'center', fontFamily: 'Cairo' }}>{`${order.billing.first_name} ${order.billing.last_name}`}</TableCell>
                <TableCell style={{ textAlign: 'center', fontFamily: 'Cairo' }}>{`${order.shipping.first_name} ${order.shipping.last_name}`}</TableCell>
                <TableCell style={{ textAlign: 'center', fontFamily: 'Cairo' }}>
                  <Button style={{ textAlign: 'center', fontFamily: 'Cairo' }} onClick={() => handleViewDetails(order)} variant="outlined" color="primary">
                    شاهد التفاصيل
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Order Details</DialogTitle>
        <DialogContent dividers>
          {selectedOrder && (
            <>
              <div>ID: {selectedOrder.id}</div>
              <div>Status: {selectedOrder.status}</div>
              <div>Billing First Name: {selectedOrder.billing.first_name}</div>
              <div>Billing Last Name: {selectedOrder.billing.last_name}</div>
              <div>Billing Company: {selectedOrder.billing.company}</div>
              <div>Billing Address 1: {selectedOrder.billing.address_1}</div>
              <div>Billing Address 2: {selectedOrder.billing.address_2}</div>
              <div>Billing City: {selectedOrder.billing.city}</div>
              <div>Billing State: {selectedOrder.billing.state}</div>
              <div>Billing Postcode: {selectedOrder.billing.postcode}</div>
              <div>Billing Country: {selectedOrder.billing.country}</div>
              <div>Billing Email: {selectedOrder.billing.email}</div>
              <div>Billing Phone: {selectedOrder.billing.phone}</div>
              <div>Shipping First Name: {selectedOrder.shipping.first_name}</div>
              <div>Shipping Last Name: {selectedOrder.shipping.last_name}</div>
              <div>Shipping Company: {selectedOrder.shipping.company}</div>
              <div>Shipping Address 1: {selectedOrder.shipping.address_1}</div>
              <div>Shipping Address 2: {selectedOrder.shipping.address_2}</div>
              <div>Shipping City: {selectedOrder.shipping.city}</div>
              <div>Shipping State: {selectedOrder.shipping.state}</div>
              <div>Shipping Postcode: {selectedOrder.shipping.postcode}</div>
              <div>Shipping Country: {selectedOrder.shipping.country}</div>
              <div>Shipping Phone: {selectedOrder.shipping.phone}</div>
              <div>Line Items:</div>
              <ul>
                {selectedOrder.line_items.map((item) => (
                  <li key={item.id}>
                    Name: {item.name}
                    <br />
                    SKU: <span style={{color:"red"}}>{item.sku}</span>
                    <br />
                    Quantity: {item.quantity}
                    <br />
                    Total: {item.total} SAR
                    <br/>
                    <h3>from: <span>{item.sku.match(/^[a-zA-Z]{2,}\d{2,}_\d+(_\d+)?$/) ? <span style={{color:"red"}}>moardeen</span>
                    : <span style={{color:"red"}}>ali express</span>}</span></h3>

                    {item.sku.match(/^[a-zA-Z]{2,}\d{2,}_\d+(_\d+)?$/) ? (
                      <h3 style={{fontFamily:"Cairo"}}>
                        <span>اسم المرود : </span>
                        <span style={{color:"red"}}>{suppliersData.find((supplier) => item.sku.substring(0, 3) === supplier.supplyersku.substring(0, 3))?.name || ''}</span>
                      </h3>
                    ) : null}
                    {item.sku.match(/^[a-zA-Z]{2,}\d{2,}_\d+(_\d+)?$/) ? (
                      <h3 style={{fontFamily:"Cairo"}}>
                        <span>رقم المرود : </span>
                        <span style={{color:"red"}}>{suppliersData.find((supplier) => item.sku.substring(0, 3) === supplier.supplyersku.substring(0, 3))?.supplyerNumber || ''}</span>
                      </h3>
                    ) : null}
                    <br />
                    {item.sku.match(/^[a-zA-Z]{2,}\d{2,}_\d+(_\d+)?$/)  ? 
                      <Button
                        onClick={() => {
                          const matchedSupplier = suppliersData.find(
                            (supplier) => item.sku.substring(0, 4) === supplier.supplyersku.substring(0, 4)
                          );

                          if (matchedSupplier) {
                            const billingDetails = `
                              الاسم الأول للفواتير: ${selectedOrder.billing.first_name}
                              الاسم الأخير للفواتير: ${selectedOrder.billing.last_name}
                              شركة الفواتير: ${selectedOrder.billing.company}
                              عنوان الفواتير 1: ${selectedOrder.billing.address_1}
                              عنوان الفواتير 2: ${selectedOrder.billing.address_2}
                              مدينة الفواتير: ${selectedOrder.billing.city}
                              ولاية الفواتير: ${selectedOrder.billing.state}
                              رمز الفواتير البريدي: ${selectedOrder.billing.postcode}
                              بلد الفواتير: ${selectedOrder.billing.country}
                              البريد الإلكتروني للفواتير: ${selectedOrder.billing.email}
                              هاتف الفواتير: ${selectedOrder.billing.phone}
                            `.trim();

                            const shippingDetails = `
                              الاسم الأول للشحن: ${selectedOrder.shipping.first_name}
                              الاسم الأخير للشحن: ${selectedOrder.shipping.last_name}
                              شركة الشحن: ${selectedOrder.shipping.company}
                              عنوان الشحن 1: ${selectedOrder.shipping.address_1}
                              عنوان الشحن 2: ${selectedOrder.shipping.address_2}
                              مدينة الشحن: ${selectedOrder.shipping.city}
                              ولاية الشحن: ${selectedOrder.shipping.state}
                              رمز الشحن البريدي: ${selectedOrder.shipping.postcode}
                              بلد الشحن: ${selectedOrder.shipping.country}
                              هاتف الشحن: ${selectedOrder.shipping.phone}
                            `.trim();

                            const message = `
                              مرحبًا، أود تقديم طلب للمنتج التالي:
                              \nاسم المنتج: ${item.name}
                              \nمعرف المنتج: ${item.id}
                              \nالكمية: ${item.quantity}
                              \nالسعر الإجمالي: ${item.total} ريال سعودي
                              \n\nتفاصيل الفواتير:\n${billingDetails}
                              \n\nتفاصيل الشحن:\n${shippingDetails}
                            `;

                            const encodedMessage = encodeURIComponent(message);
                            const whatsappLink = `https://wa.me/${matchedSupplier.supplyerNumber}?text=${encodedMessage}`;
                            window.open(whatsappLink, '_blank');
                          } else {
                            console.error('لم يتم العثور على مورد مطابق لرمز المنتج.');
                          }
                        }}
                        variant="contained"
                        color="primary"
                      >
                        اطلب
                      </Button> 
                      : 
                      <Button
                        onClick={() => handleOrderClick(item.sku)}
                        variant="contained"
                        color="primary"
                      >
                        اطلب
                      </Button>
                    }
                    <br /><br />
                  </li>
                ))}
              </ul>
            </>
          )}
        </DialogContent>
        <DialogActions style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
          <FormControl variant="outlined">
            <InputLabel id="status-select-label">تغير حالة</InputLabel>
            <Select
              labelId="status-select-label"
              id="status-select"
              value={selectedStatus}
              onChange={handleStatusChange}
              label="تغير حالة"
              style={{ marginLeft: 10, fontFamily: 'Cairo' }}
            >
              <MenuItem style={{ fontFamily: 'Cairo' }} value="processing">جاري المعالجة</MenuItem>
              <MenuItem style={{ fontFamily: 'Cairo' }} value="completed">تم الطلب</MenuItem>
            </Select>
          </FormControl>
        </DialogActions>
        {statusModified && (
          <Button style={{ marginBottom: '10px', width: '50%', margin: 'auto' }} variant="contained" color="primary" onClick={updateOrderStatus}>
            تأكيد
          </Button>
        )}
        <br />
      </Dialog>
    </>
  );
};

export default StoreOrdersTable;
