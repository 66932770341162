import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ModeProvider } from './components/ModeContext/ModeContext';
import './App.css';
import Home from './Pages/Home/Home';
import ProductDetails from './components/ProductDetails/ProductDetails';
import SignUpAsAliExpress from './Pages/SignUp/SignUp';
import ProductsPage from './Pages/ProductsPage/Products';
import ContactForm from './Pages/ContactUs/ContactUs';
import AdminForm from './AdminForm/AdminForm';
import HomeAdmin from './components/dashboard/HomeDashboard';
import ContactsTable from './Pages/ContatctsAdmin/ContatcsMessages';
import OrdersComponent from './Pages/OrdersForAdminAliexpress/OrdersForAdminAliexpress';
import LoginForm from './Pages/LoginUsersForm/LoginUsersForm';
import DahboardUser from './components/dashboardusers/DashboardUser';
import ProductTable from './Pages/ProductsUser/ProductsUser';
import AboutUs from './Pages/WhoWeAre/WhoWeare';
import ImportAttributesFromExternalAPI from './components/test';
import NotFound from './components/NotFound';
import SupplierTable from './components/Moardeen';

function App() {
  return (
    <Router>
      <ModeProvider>
        
        <Routes>
          <Route
            path="/"
            element={
              <div className="App">
                <Home />
              </div>
            }
          />
          <Route path="product/:id" element={<ProductDetails />} />
          <Route path="signup" element={<SignUpAsAliExpress />} />
          <Route path="products" element={<ProductsPage />} />
          <Route path="contactus" element={<ContactForm />} />
          <Route path="admin" element={<AdminForm/>} />
          <Route path="dashboard" element={<HomeAdmin/>} />
          <Route path="contactsmessages" element={<ContactsTable/>} />
          <Route path="orders" element={<OrdersComponent/>} />
          <Route path="login" element={<LoginForm/>} />
          <Route path="getUserProducts" element={<DahboardUser/>} />
          <Route path="dashboarduser" element={<ProductTable/>} />
          <Route path="aboutus" element={<AboutUs/>} />
          <Route path="test" element={<ImportAttributesFromExternalAPI/>} />
          <Route path="*" element={<NotFound/>} />
          <Route path="moardeen" element={<SupplierTable/>} />
      
        </Routes>
      </ModeProvider>
    </Router>
  );
}

export default App;
