import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import Image from "../../images/15938.gif"
import Swal from "sweetalert2";
import ResponsiveAppBar from "../../components/Navbar/Navbar";
import { ModeContext } from "../../components/ModeContext/ModeContext";
import { useContext } from 'react';
function SignUpAsAliExpress() {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const { modeLabel } = useContext(ModeContext);

  const [cartData, setCartData] = useState([]);

  useEffect(() => {
    const localStorageData = JSON.parse(localStorage.getItem("cart")) || [];
    setCartData(localStorageData);
  }, []);

  const handleregister = ()=>{
    Swal.fire({
      title: "أنت لست بمستخدم حالي يمكنك التسجيل معنا",
      showCancelButton: true,
      confirmButtonText: "تسجيل",
      cancelButtonText: "إلغاء",
      showLoaderOnConfirm: true,
      customClass: {
        title: "swal-title",
        cancelButton: "swal-cancel-button",
        confirmButton: "swal-confirm-button",
        htmlContainer: "swal-html-container",
      },
      html: `
      <form id="registrationForm" style="max-width: 100%; margin: auto;">
        <input style="width: 100%;direction:rtl; padding: 10px; box-sizing: border-box; margin-bottom: 10px;" type="text" id="firstName" name="firstName" class="swal-input" placeholder="الاسم الأول" required>
        <br />
        <input style="width: 100%;direction:rtl; padding: 10px; box-sizing: border-box; margin-bottom: 10px;" type="text" id="lastName" name="lastName" class="swal-input" placeholder="الاسم الأخير" required>
        <br />
        <!-- New input fields for username, password, and confirm password -->
        <input style="width: 100%;direction:rtl; padding: 10px; box-sizing: border-box; margin-bottom: 10px;" type="text" id="username" name="username" class="swal-input" placeholder="اسم المستخدم" required>
        <br />
        <input style="width: 100%;direction:rtl; padding: 10px; box-sizing: border-box; margin-bottom: 10px;" type="password" id="password" name="password" class="swal-input" placeholder="كلمة المرور" required>
        <br />
        <input style="width: 100%;direction:rtl; padding: 10px; box-sizing: border-box; margin-bottom: 10px;" type="password" id="confirmPassword" name="confirmPassword" class="swal-input" placeholder="تأكيد كلمة المرور" required>
        <br />
        <input style="width: 100%;direction:rtl; padding: 10px; box-sizing: border-box; margin-bottom: 10px;" type="text" id="address" name="address" class="swal-input" placeholder="العنوان الخاص بك" required>
        <br />
        <input style="width: 100%;direction:rtl; padding: 10px; box-sizing: border-box; margin-bottom: 10px;" 
        type="text" id="websiteUrl" name="websiteUrl" class="swal-input" placeholder="عنوان موقعك الالكتروني" 
        pattern="https?://.*" title="يرجى إدخال عنوان URL صحيح بدءًا بـ http:// أو https://" required>
    
        <br />
        <input style="width: 100%;direction:rtl; padding: 10px; box-sizing: border-box; margin-bottom: 10px;" type="number" id="phone" name="phone" class="swal-input" placeholder="رقم هاتفك" required>
        <br />
        <input style="width: 100%;direction:rtl; padding: 10px; box-sizing: border-box; margin-bottom: 10px;" type="email" id="email" name="email" class="swal-input" placeholder="عنوان بريدك الإلكتروني" required>
        <br />
        <!-- Additional input fields for Consumer Key and Secret Key -->
        <input style="width: 100%;direction:rtl; padding: 10px; box-sizing: border-box; margin-bottom: 10px;" type="text" id="consumerKey" name="consumerKey" class="swal-input" placeholder="كونسيمر كي" required>
        <br />
        <input style="width: 100%;direction:rtl; padding: 10px; box-sizing: border-box; margin-bottom: 10px;" type="text" id="secretKey" name="secretKey" class="swal-input" placeholder="سيكريت كي" required>
        <br />
      </form>
    `,
    
    preConfirm: async () => {
      const formData = {
        firstName: getInputValue("firstName"),
        lastName: getInputValue("lastName"),
        username: getInputValue("username"),
        password: getInputValue("password"),
        confirmPassword: getInputValue("confirmPassword"),
        address: getInputValue("address"),
        websiteUrl: validateUrl(getInputValue("websiteUrl")),
        phone: getInputValue("phone"),
        email: getInputValue("email"),
        consumerKey: getInputValue("consumerKey"),
        secretKey: getInputValue("secretKey"),
      };

      function getInputValue(id) {
        const inputElement = document.getElementById(id);
        return inputElement ? inputElement.value : null;
      }

      function validateUrl(url) {
        const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
        return url && url.match(urlRegex) ? url : null;
      }

      console.log(formData);

      try {
        const response = await fetch("https://gostookbackend.gostook.com/RegisterDataForUsers.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });

        const data = await response.json();
        console.log(data);

        if (response.ok) {
          if (data.status === "success") {
            Swal.fire({
              title: "Success!",
              text: data.message,
              icon: "success",
            });
          } else {
            Swal.fire({
              title: "Error!",
              text: data.message,
              icon: "error",
            });
          }
        } else {
          Swal.fire({
            title: "Error!",
            text: "Failed to submit data.",
            icon: "error",
          });
        }
      } catch (error) {
        console.error("Error:", error);
        Swal.fire({
          title: "Error!",
          text: "An error occurred. Please try again later.",
          icon: "error",
        });
      }
    },
  });
};



  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const requestData = {
        userData: formData,
        cartData: cartData,
      };

      const response = await fetch(
        "https://gostookbackend.gostook.com/handleRequestFromUsers.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      console.log(requestData);

      const data = await response.json();
      console.log(data.message);
      if (response.ok) {
        if (data.isRegistered || data.message === "Data inserted successfully") {
          console.log(data.message);
          Swal.fire({
            title: "تم اضافة منتجاتك الى المتجر الخاص بك بنجاح",
            showClass: {
              popup: `
                animate__animated
                animate__fadeInUp
                animate__faster
              `
            },
            hideClass: {
              popup: `
                animate__animated
                animate__fadeOutDown
                animate__faster
              `
            }
          });
          localStorage.removeItem("cart");
          setCartData([]); // Clear cart data from state
        } else if (data.message === "User not registered") {
          // Prompt the user to register
          console.log(data.message);
          Swal.fire({
            title: "أنت لست بمستخدم حالي يمكنك التسجيل معنا",
            showCancelButton: true,
            confirmButtonText: "تسجيل",
            cancelButtonText: "إلغاء",
            showLoaderOnConfirm: true,
            customClass: {
              title: "swal-title",
              cancelButton: "swal-cancel-button",
              confirmButton: "swal-confirm-button",
              htmlContainer: "swal-html-container",
            },
            html: `
            <form id="registrationForm" style="max-width: 100%; margin: auto;">
              <input style="width: 100%;direction:rtl; padding: 10px; box-sizing: border-box; margin-bottom: 10px;" type="text" id="firstName" name="firstName" class="swal-input" placeholder="الاسم الأول" required>
              <br />
              <input style="width: 100%;direction:rtl; padding: 10px; box-sizing: border-box; margin-bottom: 10px;" type="text" id="lastName" name="lastName" class="swal-input" placeholder="الاسم الأخير" required>
              <br />
              <!-- New input fields for username, password, and confirm password -->
              <input style="width: 100%;direction:rtl; padding: 10px; box-sizing: border-box; margin-bottom: 10px;" type="text" id="username" name="username" class="swal-input" placeholder="اسم المستخدم" required>
              <br />
              <input style="width: 100%;direction:rtl; padding: 10px; box-sizing: border-box; margin-bottom: 10px;" type="password" id="password" name="password" class="swal-input" placeholder="كلمة المرور" required>
              <br />
              <input style="width: 100%;direction:rtl; padding: 10px; box-sizing: border-box; margin-bottom: 10px;" type="password" id="confirmPassword" name="confirmPassword" class="swal-input" placeholder="تأكيد كلمة المرور" required>
              <br />
              <input style="width: 100%;direction:rtl; padding: 10px; box-sizing: border-box; margin-bottom: 10px;" type="text" id="address" name="address" class="swal-input" placeholder="العنوان الخاص بك" required>
              <br />
              <input style="width: 100%;direction:rtl; padding: 10px; box-sizing: border-box; margin-bottom: 10px;" 
              type="text" id="websiteUrl" name="websiteUrl" class="swal-input" placeholder="عنوان موقعك الالكتروني" 
              pattern="https?://.*" title="يرجى إدخال عنوان URL صحيح بدءًا بـ http:// أو https://" required>
          
              <br />
              <input style="width: 100%;direction:rtl; padding: 10px; box-sizing: border-box; margin-bottom: 10px;" type="number" id="phone" name="phone" class="swal-input" placeholder="رقم هاتفك" required>
              <br />
              <input style="width: 100%;direction:rtl; padding: 10px; box-sizing: border-box; margin-bottom: 10px;" type="email" id="email" name="email" class="swal-input" placeholder="عنوان بريدك الإلكتروني" required>
              <br />
              <!-- Additional input fields for Consumer Key and Secret Key -->
              <input style="width: 100%;direction:rtl; padding: 10px; box-sizing: border-box; margin-bottom: 10px;" type="text" id="consumerKey" name="consumerKey" class="swal-input" placeholder="كونسيمر كي" required>
              <br />
              <input style="width: 100%;direction:rtl; padding: 10px; box-sizing: border-box; margin-bottom: 10px;" type="text" id="secretKey" name="secretKey" class="swal-input" placeholder="سيكريت كي" required>
              <br />
            </form>
          `,
          
            preConfirm: async () => {
              // Handle registration logic here
              const formData = {
                firstName: document.getElementById("firstName").value,
                lastName: document.getElementById("lastName").value,
                username: document.getElementById("username").value,
                password: document.getElementById("password").value,
                confirmPassword: document.getElementById("confirmPassword").value,
                address: document.getElementById("address").value,
                websiteUrl: validateUrl(document.getElementById("websiteUrl").value), // Validate URL
                phone: document.getElementById("phone").value,
                email: document.getElementById("email").value,
                consumerKey: document.getElementById("consumerKey").value,
                secretKey: document.getElementById("secretKey").value,
              };
              
              function validateUrl(url) {
                // Regular expression to match URLs with http:// or https:// structure
                const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
                if (url.match(urlRegex)) {
                  return url; // Return the URL if it matches the pattern
                } else {
                  // If the URL does not match the pattern, display an error or handle it accordingly
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Please enter a valid URL starting with http:// or https://",
                  });
                  // You can return null or an empty string here, depending on your requirements
                  return null;
                }
              }

              console.log(formData)
          
              try {
                const response = await fetch("https://gostookbackend.gostook.com/RegisterDataForUsers.php", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(formData)
                });
    
                const data = await response.json();
                console.log(data);
    
                if (response.ok) {
                    // Check status and display appropriate message
                    if (data.status === 'success') {
                        Swal.fire({
                            title: "Success!",
                            text: data.message,
                            icon: "success"
                        });
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: data.message,
                            icon: "error"
                        });
                    }
                } else {
                    // Handle other HTTP errors
                    Swal.fire({
                        title: "Error!",
                        text: "Failed to submit data.",
                        icon: "error"
                    });
                }
            } catch (error) {
                console.error("Error:", error);
                // Handle network or other errors
                Swal.fire({
                    title: "Error!",
                    text: "An error occurred. Please try again later.",
                    icon: "error"
                });
            }
            },
          });
          
          
        } else if (data.message === "Incorrect password") {
          // Incorrect password, show error message
          console.log(data.message);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "خطأ في كلمة المرور الخاصة بك",
          });
        }
      } else {
        console.error("Registration failed:", data.message);
        // Handle registration failure, e.g., show an error message
      }
    } catch (error) {
      console.error("An error occurred during registration:", error);
      // Handle unexpected errors
    }
  };

  const getImageUrl = (imageName) =>
    `https://gostookbackend.gostook.com/${imageName}`;

  const calculateTotal = () => {
    return cartData
      .reduce((total, item) => total + parseFloat(item.total), 0)
      .toFixed(2);
  };

  const handleDeleteItem = (index) => {
    const updatedCartData = [...cartData];
    updatedCartData.splice(index, 1);
    setCartData(updatedCartData);
    localStorage.setItem("cart", JSON.stringify(updatedCartData));
  };

  return (
    <div>
      <ResponsiveAppBar /> <br /> <br />
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <div>
          <img style={{ height: "300px" }} src={Image} alt="pointer image" />
        </div>
        <Button
          variant="primary"
          onClick={handleregister}
          style={{
            width: "200px",
            borderRadius: "5px",
            fontSize: "20px",
            color: "white",
            wordSpacing: "5px",
            marginTop: "20px",
            fontFamily: "Cairo,sans-serif",
            backgroundColor: "#1b7d4f",
            cursor: "pointer",
            border: "none"}}
              >
          سجل معنا
        </Button>
      </div>
    </div>
  );
}

export default SignUpAsAliExpress;
