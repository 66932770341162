import React from 'react';
import Slider from 'infinite-react-carousel';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import ProductionQuantityLimitsOutlinedIcon from '@mui/icons-material/ProductionQuantityLimitsOutlined';
import PhonelinkOutlinedIcon from '@mui/icons-material/PhonelinkOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import { Button } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { ModeContext } from '../ModeContext/ModeContext';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

const SimpleSlider = () => {
  const { modeLabel } = useContext(ModeContext);
  const slidesToShowMobile = window.innerWidth <= 800 ? 2 : 4;

  return (
    <div style={{ backgroundColor: modeLabel === 'dark mode' ? '#1e1e1e' : '#e9e9e9' }}>
      <h1 className='label-mazan text-black text-center' style={{ color: modeLabel === 'dark mode' ? 'white' : 'black' }}>
        مميزاتنا
      </h1>
      <Slider
        arrows={false}
        dots={false}
        duration={1000}
        autoplay
        slidesToShow={slidesToShowMobile}
        className="custom-slider"
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', border: '1px solid red', height: '150px', width: '150px' }}>
          <div style={{ backgroundColor: '#1b7d4f', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100px', height: '100px' }}>
            <LocalShippingOutlinedIcon style={{ fontSize: '64px',color:"white"  }} />
          </div>
          <h3 style={{ marginTop: '10px', textAlign: "left", color: modeLabel === 'dark mode' ? 'white' : 'black' }}>الشحن السريع</h3>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', border: '1px solid red', height: '150px', width: '150px' }}>
          <div style={{ backgroundColor: '#1b7d4f', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100px', height: '100px' }}>
            <ProductionQuantityLimitsOutlinedIcon style={{ fontSize: '64px',color:"white"  }} />
          </div>
          <h3 style={{ marginTop: '10px', textAlign: "left", color: modeLabel === 'dark mode' ? 'white' : 'black' }}>توفير المنتجات</h3>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', border: '1px solid red', height: '150px', width: '150px' }}>
          <div style={{ backgroundColor: '#1b7d4f', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100px', height: '100px' }}>
            <PhonelinkOutlinedIcon style={{ fontSize: '64px',color:"white"  }} />
          </div>
          <h3 style={{ marginTop: '10px', textAlign: "left", color: modeLabel === 'dark mode' ? 'white' : 'black' }}>الربط الالي</h3>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', border: '1px solid red', height: '150px', width: '150px' }}>
          <div style={{ backgroundColor: '#1b7d4f', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100px', height: '100px' }}>
            <MonetizationOnOutlinedIcon style={{ fontSize: '64px',color:"white"  }} />
          </div>
          <h3 style={{ marginTop: '10px', textAlign: "left", color: modeLabel === 'dark mode' ? 'white' : 'black' }}>تحديد نسبة الربح</h3>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', border: '1px solid red', height: '150px', width: '150px' }}>
          <div style={{ backgroundColor: '#1b7d4f', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100px', height: '100px' }}>
            <CreditCardOutlinedIcon style={{ fontSize: '64px',color:"white" }} />
          </div>
          <h3 style={{ marginTop: '10px', textAlign: "left", color: modeLabel === 'dark mode' ? 'white' : 'black' }}>بدون رأس مال</h3>
        </div>
        {/* Add more slider items as needed */}
      </Slider>
      <div style={{ marginTop: "50px" }}>
        <Link to="/aboutus">
        <Button style={{ backgroundColor: "#1b7d4f",color: "white",fontFamily: "Cairo",fontSize: "25px",borderRadius: "20px",marginBottom: modeLabel === 'dark mode' ? '20px' : '0px' }} startIcon={<ArrowBackIosNewIcon/>} >
          للمزيـــد من التفـــاصــيل
        </Button>
        </Link>
      </div>
    </div>
  )
}

export default SimpleSlider;
