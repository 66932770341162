import React from "react";
import "./Aboutgostook.css"
import { useContext } from "react";
import { ModeContext } from "../ModeContext/ModeContext";
import image from "../../images/output-onlinegiftools (3).gif"
const Aboutgostook = ()=>{
    const { modeLabel } = useContext(ModeContext);
    return (
        <div className="Parent">
            <div className="child1">
                <img className="IMAGEE" src={image} alt="image"/>
            </div>
            <div className="child2">
                <h3 className="label-mazan2 text-black text-right" style={{ color: modeLabel === 'dark mode' ? 'white' : 'black'}}>
                    عن جوستوك
                </h3>
                <p className="text-mazan2 p-md mt-3 text-black col-md-12" style={{ color: modeLabel === 'dark mode' ? 'white' : 'black'}}>
  تأسس مشروعك الالكتروني بسهولة ومرونة
  <br/>
  احصل على الـدعم الكــامل لبـدء مشروعــك 
  <br/>
  توفر خـدمـات الـشحـن الآمـنـة والمتـكامـلة 
  <br/>
  قم بتـخزين وتوفـير منـتـجاتك بكفاءة عالـيـة
  <br/>
  خدمات التغليف والشحن بكل يسر وسهولة
  <br/>
  يتناسب مع جميع  المشاريع 
</p>

            </div>
        </div>
    )
}

export default Aboutgostook