import React from 'react';
import Slider from 'react-infinite-logo-slider';
import logo1 from '../images/loogo3.png';
import logo2 from '../images/loogo4.png';
import logo3 from '../images/loogo5.png';
import logo4 from '../images/loogo6.png';
import logo5 from '../images/loogo5.png';
import logo6 from '../images/loogo8.png';
import logo7 from '../images/loogo9.png';
import logo8 from '../images/522411ff-d1e5-44cf-9826-c4464349cd77.png';

const JIF2 = () => {
    const logos = [
        { id: 1, src: logo1 },
        { id: 2, src: logo2 },
        { id: 3, src: logo3 },
        { id: 4, src: logo4 },
        { id: 5, src: logo5 },
        { id: 6, src: logo6 },
        { id: 7, src: logo7 },
        { id: 8, src: logo8 },
    ];

    return (
        <div style={{ backgroundColor: "#b5b5b5" }}>
            <Slider
                width="250px"
                duration={40}
                pauseOnHover={true}
                blurBorders={false}
                blurBorderColor={'#fff'}
                style={{ backgroundColor: "black" }}
            >
                {logos.map((logo) => (
                    <Slider.Slide key={logo.id}>
                        <img
                            src={logo.src}
                            alt={`Logo ${logo.id}`}
                            style={{ width: "120px", maxHeight: "150px",padding:"5px"}}
                        />
                    </Slider.Slide>
                ))}
            </Slider>
        </div>
    );
};

export default JIF2;
