import React from "react";
import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import image from "../../images/virtual-assistant.svg";
import "./ContactForm.css";
import ResponsiveAppBar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { useContext } from "react";
import { ModeContext } from "../../components/ModeContext/ModeContext";

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm();
  const { modeLabel } = useContext(ModeContext);

  const onSubmit = async (data) => {
    try {
      const formData = {
        name: data.name,
        email: data.email,
        message: data.message,
      };
  
      console.log("Form data before sending:", formData); // Log the formData
  
      const response = await fetch("https://gostookbackend.gostook.com/handlecontacts.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
  
      const responseData = await response.json();
      console.log("Form submission response:", responseData);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  

  return (
    <>
      <ResponsiveAppBar />
      <div style={{backgroundColor:modeLabel === "dark mode" ? "#1e1e1e" :"white"}} className="ParentFormContact">
        <div>
          <img src={image} className="ImageContact" />
        </div>
        <form className="Form" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  fontFamily: "Cairo",
                  fontWeight: "500",
                  color:modeLabel === "dark mode" ? "white" :"black",
                }}
                variant="h2"
              >
                اتــصل بنا
              </Typography>
            </Grid>
            <Grid item xs={12}>
            <TextField
  {...register("name", {
    required: "Name is required",
    minLength: {
      value: 3,
      message: "Name should be at least 3 characters",
    },
  })}
  textAlign="right"
  placeholder="الاسم"
  fullWidth
  error={!!errors.name}
  helperText={errors.name?.message}
  sx={{
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: modeLabel === "dark mode" ? "white" : "black",
    },
  }}
  InputProps={{
    style: {
      color: modeLabel === "dark mode" ? "white" : "black",
    },
  }}
/>

            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Invalid email address",
                  },
                })}
                textAlign="right"
                placeholder="الايميل"
                fullWidth
                error={!!errors.email}
                helperText={errors.email?.message}
                sx={{
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: modeLabel === "dark mode" ? "white" : "black",
                  },
                }}
                InputProps={{
                  style: {
                    color: modeLabel === "dark mode" ? "white" : "black",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register("message", {
                  required: "Message is required",
                  minLength: {
                    value: 10,
                    message: "Message should be at least 10 characters",
                  },
                })}
                textAlign="right"
                placeholder="رسالتك"
                multiline
                rows={4}
                fullWidth
                error={!!errors.message}
                helperText={errors.message?.message}
                sx={{
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: modeLabel === "dark mode" ? "white" : "black",
                  },
                }}
                InputProps={{
                  style: {
                    color: modeLabel === "dark mode" ? "white" : "black",
                  },
                }}
              />
            </Grid>
            <div style={{ textAlign: "center",marginTop:"20px" }}>
              <Grid item xs={20}>
                <Button
                  style={{
                    fontFamily: "Cairo",
                    width: "100%",
                  }}
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "ارســــال"}
                </Button>
              </Grid>
            </div>
            {isSubmitSuccessful && (
              <Grid item xs={12}>
                <Alert severity="success">Form submitted successfully!</Alert>
              </Grid>
            )}
          </Grid>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default ContactForm;
