import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Header from '../../components/Hedaer.jsx/Header';
import Footer from '../../components/Footer/Footer';
import { ModeContext } from '../../components/ModeContext/ModeContext';
import { useContext } from 'react';
const AboutUs = () => {
  const { modeLabel } = useContext(ModeContext);

  return (
    <div style={{backgroundColor: modeLabel === 'dark mode' ? '#121212' : 'white'}}>
      <Header />
      <Container maxWidth="lg" style={{ marginTop: '50px',marginBottom:"50px" }}>
        <Typography variant="h3" align="center" gutterBottom style={{ fontFamily: 'Arial',
         color: '#333',fontFamily:"Cairo",color: modeLabel === 'dark mode' ? 'white' : 'black' }}>
          من نحن
        </Typography>
        <Grid container spacing={3} style={{marginTop:"50px"}}>
          <Grid item xs={12} sm={6}>
            <Card style={{ boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #ccc',backgroundColor: modeLabel === 'dark mode' ? '#121212' : 'white' }}>
              <CardContent>
                <Typography variant="h5" gutterBottom style={{ color: '#333',
                textAlign:"right",fontFamily:"Cairo",color: modeLabel === 'dark mode' ? 'white' : '#333' }}>
                  رسالتنا
                </Typography>
                <Typography variant="body1" style={{ textAlign:"right",fontFamily:"Cairo",color: modeLabel === 'dark mode' ? 'white' : '#333' }}>
                  نحن نسعى لتقديم أفضل الخدمات والمنتجات لعملائنا بجودة عالية وبأسعار تنافسية، من خلال التزامنا بالتميز والابتكار في كل ما نقدمه.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card style={{ boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #ccc',backgroundColor: modeLabel === 'dark mode' ? '#121212' : 'white'  }}>
              <CardContent>
                <Typography variant="h5" gutterBottom style={{ color: modeLabel === 'dark mode' ? 'white' : '#333' ,textAlign:"right",fontFamily:"Cairo" }}>
                  رؤيتنا
                </Typography>
                <Typography variant="body1" style={{ color: modeLabel === 'dark mode' ? 'white' : '#333' ,textAlign:"right",fontFamily:"Cairo" }}>
                  أن نصبح الشركة الرائدة في مجالنا، من خلال تقديم خدمات ومنتجات تفوق توقعات عملائنا وتسهم في تحقيق رضاهم ونجاحهم.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card style={{ boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #ccc',backgroundColor: modeLabel === 'dark mode' ? '#121212' : 'white'  }}>
              <CardContent>
                <Typography variant="h5" gutterBottom style={{ color: modeLabel === 'dark mode' ? 'white' : '#333',textAlign:"right",fontFamily:"Cairo" }}>
                  قيمنا
                </Typography>
                <Typography variant="body1" style={{color: modeLabel === 'dark mode' ? 'white' : '#333',textAlign:"right",fontFamily:"Cairo" }}>
                  - التميز والجودة في كل ما نقدمه <br />
                  - الشفافية والأمانة في التعامل مع عملائنا <br />
                  - الابتكار والتطوير المستمر لتحسين خدماتنا ومنتجاتنا <br />
                  - الاحترافية والالتزام بالمواعيد والتسليم الموعود <br />
                  - الاستدامة والمسؤولية الاجتماعية تجاه المجتمع والبيئة
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Footer/>
    </div>
  );
};

export default AboutUs;
