import React, { useState, useEffect, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import image from "../../images/cat-new-cats.gif";
import ResponsiveAppBar from '../Navbar/Navbar';
import './ProductDetails.css';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TextField } from '@mui/material';
import Swal from 'sweetalert2';
import CircularProgressThickness from '../MoreRequests/Spinner';
import { useContext } from "react";
import { ModeContext } from "../../components/ModeContext/ModeContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ProductDetails() {
  const setSelectedProductId = useState(null)[1];
  const [open, setOpen] = React.useState(false);
  const [formValues, setFormValues] = useState({
    username: '',
    password: '',
  });

  const { modeLabel } = useContext(ModeContext);

  const localStorageData = JSON.parse(localStorage.getItem("userData")) || {};
  const url = `https://matgar.gostook.com/wp-json/wc/v3/products/?consumer_key=ck_71e10dd1e6b6d9da3672a636532add27a738b9ed&consumer_secret=cs_084caf3c8964ba007ef7957cbad3429f9ea76268&per_page=100`;
  console.log("urlurl",url);
  const fakeUrl = `${localStorageData.url}/wp-json/wc/v3/products/?consumer_key=${localStorageData.consumerKey}&consumer_secret=${localStorageData.secretKey}`;
  console.log('fakeurlfakeUrlfakeUrl',fakeUrl);
  console.log("userDatauserDatauserData",localStorageData);

  const [productVariations, setProductVariations] = useState([]);
  const [idVarions, setIdVarions] = useState();
  const [attributeIdMap, setAttributeIdMap] = useState({});
  const isUserTokenPresent = localStorage.getItem('isUser');
  const [postedAttributes, setPostedAttributes] = useState([]);
  const [loading,setLoading] = useState(true);

  console.log("attributeIdMap",attributeIdMap);
  // console.log("attributeIdMap[attribute.slug]",attributeIdMap["pa_السفن-من"])
  const handleClose = () => {
    setOpen(false);
  };
  

  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [dataOfProductPostedAttriputes,setDataOfProductPostedAttriputes] = useState();

  const slugOptionsMap = dataOfProductPostedAttriputes?.reduce((acc, item) => {
    acc[item.slug] = item.options;
    return acc;
  }, {}) || {};
  console.log("slugOptionsMap",slugOptionsMap);

  console.log("dataOfProductPostedAttriputesdataOfProductPostedAttriputes",dataOfProductPostedAttriputes);
  const postVariationsData = async (idVarions, variationsData) => {
    console.log("postVariationsData idVariations:", idVarions);
    const url = ` ${localStorageData.url}/wp-json/wc/v3/products/${idVarions}/variations?consumer_key=${localStorageData.consumerKey}&consumer_secret=${localStorageData.secretKey}&per_page=100`;
 
    try {
      // Loop through variationsData and post data for each variation
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(variationsData),
      });

      const data = await response.json();

      console.log(`Variation ID ${data.id} posted successfully.`);
      console.log("this is all data variations", data);
      // Handle success or error based on the response...
    } catch (error) {
      console.error('Error posting variations data:', error);
      // Handle error...
    }
  };

  useEffect(() => {
    const handlePostVariations = async () => {
      // Only proceed if idVarions is defined
      if (idVarions && productVariations.length > 0) {
        for (const variation of productVariations) {
          const attributes = variation.attributes && variation.attributes.length > 0
            ? variation.attributes.map(attribute => {
              console.log("current varaition",variation);
              const decodedSlug = attribute.slug.includes('%') ? decodeURIComponent(attribute.slug) : attribute.slug;
              console.log("Decoded Slug:", decodedSlug);
              console.log("attributeIdMap[decodedSlug] Current", attributeIdMap[decodedSlug]);
  
              return {
                id: attributeIdMap[decodedSlug],
                option: attribute.option,
              };
            })
            : [
              {
                id: 7, // Replace with the correct attribute ID
                option: "Black",
              },
            ];
  
          // Directly specify the attributes array
          const variationData = {
            name: variation.name || "Default Variation Name",
            price: variation.price,
            regular_price: variation.regular_price,
            manage_stock: variation.manage_stock,
            stock_quantity: variation.stock_quantity,
            stock_status: variation.stock_status,
            attributes: attributes,
            sku: variation.sku  // Replace "ل" with "l" in SKU
        };
        
  
          console.log("stock_quantity", variation.stock_quantity);
          console.log("variation.name", variation.name);
          console.log("variation.attributes", variation.attributes);
          // Call the function to post variation data
          await postVariationsData(idVarions, variationData);
        }
  
        // Show success message after all variations are posted
        Swal.fire({
          title: "تم الاستيراد!",
          text: "تم استيراد المنتج بنجاح",
          icon: "success",
        });
      }
    };
  
    handlePostVariations();
  }, [idVarions, productVariations, attributeIdMap]);

  const validateSKU = (sku) => {
    const regex = /^[a-z]+[\w$#@!&^%_*&]*$/i;
    return regex.test(sku);
  };
  
  
  

  const handleClickOpen = useCallback(async () => {
    if (isUserTokenPresent) {
      setOpen(false);
  
      const storedUserData = JSON.parse(localStorage.getItem('userData'));
  
      const url = `${localStorageData.url}/wp-json/wc/v3/products/?consumer_key=${localStorageData.consumerKey}&consumer_secret=${localStorageData.secretKey}`;
      console.log("urlurlurlurlurlurlurlurl",url);
  
      const dataOfProduct = {
        name: product.name,
        type: product.type,
        stock_quantity:product.stock_quantity,
        manage_stock:product.manage_stock,
        status: product.status,
        catalog_visibility: product.catalog_visibility,
        featured: product.featured,
        attributes: product.attributes.map((attribute) => ({
          id: attributeIdMap[attribute.slug],
          options: attribute.options,
          visible: true,
          variation: true,
        })),
        regular_price: product.price,
        description: product.description,
        images: product.images.map((image) => ({ src: image.src })),
        categories: product.categories,
        sku: product.sku,
      };
  
  
      try {
        let timerInterval;
        Swal.fire({
          title: 'انتظر من فضلك...',
          html: '<p>يتم الأن استيراد منتجك</p>',
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
            const timer = Swal.getPopup().querySelector('p');
            timerInterval = setInterval(() => {
              timer.innerHTML = `يتم الأن استيراد منتجك`;
            }, 100);
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        });
  
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(dataOfProduct),
        });
  
        // Handle the response based on success or error
        if (response.ok) {
          const data = await response.json();
          console.log('Server Response:', data);
          if (data.attributes.length === 0) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'الصفات لم ترسل بنجاح أعد المحاولة',
            });
          
            // Check if SKU is valid before deletion
            if (validateSKU(data.sku)) {
              console.log('SKU format is valid, skipping deletion.');
              Swal.fire({
                icon: 'success',
                title: 'نجاح',
                text: 'تم اضافة المنتج بنجاح',
              });
            } else {
              console.log('Invalid SKU format, proceeding with deletion.');
              const deleteResponse = await fetch(`${localStorageData.url}/wp-json/wc/v3/products/${data.id}?consumer_key=${localStorageData.consumerKey}&consumer_secret=${localStorageData.secretKey}`, {
                method: 'DELETE',
                headers: {
                  'Content-Type': 'application/json',
                },
              });
          
              if (deleteResponse.ok) {
                console.log('Product deleted successfully');
              } else {
                throw new Error(`HTTP error! Status: ${deleteResponse.status}`);
              }
            }
          
            return; // Stop execution
          }   
  
          await setIdVarions(data.id);
  
          Swal.fire({
            title: "يتم الأن اضافة جميع خصائص المنتج ",
            width: 600,
            padding: "3em",
            color: "#716add",
            background: "#fff url(/images/trees.png)",
            backdrop: `
              rgba(0, 0, 123, 0.4)
              url(${image})
              left bottom
              no-repeat
            `,
            showConfirmButton: false,
          });
        } else {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      } catch (error) {
        console.error('Error during fetch:', error);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'لقد استوردت هذا المنتج من قبل أو حدث خطأ تقني',
        });
      }
    } else {
      setOpen(true);
    }
  }, [isUserTokenPresent, product, attributeIdMap, idVarions]);
  
  
  
  
  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const [productResponse, variationsResponse] = await Promise.all([
          fetch(`https://goostook.com/wp-json/wc/v3/products/${id}?consumer_key=ck_198703302120ef330806f9d5d1b0d5e7cd032c08&consumer_secret=cs_96230e77a2a2eddf102682ae70c993924a7a6821&per_page=100`),
          fetch(`https://goostook.com/wp-json/wc/v3/products/${id}/variations?consumer_key=ck_198703302120ef330806f9d5d1b0d5e7cd032c08&consumer_secret=cs_96230e77a2a2eddf102682ae70c993924a7a6821&per_page=100`),
        ]);

        if (!productResponse.ok || !variationsResponse.ok) {
          console.error('Error fetching product data');
          return;
        }

        const [productData, variationsData] = await Promise.all([
          productResponse.json(),
          variationsResponse.json(),
        ]);

        setProduct(productData);
        setProductVariations(variationsData);

        console.log('Fetched Product:', productData);
        setDataOfProductPostedAttriputes(productData.attributes)
        console.log('Fetched Product Variations:', variationsData);
        setLoading(false)
      } catch (error) {
        console.error('Error fetching product data:', error);
      }
    };

    fetchProductData();
  }, [id]);

  useEffect(() => {
    const fetchAttributes = async () => {
      const attributesApiUrl = `${localStorageData.url}/wp-json/wc/v3/products/attributes?consumer_key=${localStorageData.consumerKey}&consumer_secret=${localStorageData.secretKey}`;
  
      try {
        const response = await fetch(attributesApiUrl);
        if (!response.ok) {
          console.error('Error fetching attributes data');
          return;
        }
  
        const attributesData = await response.json();
        const idMap = {};
  
        // Create the attribute ID map based on modified slug
        attributesData.forEach(attribute => {
          let slug = '';
  
          if (/^[a-zA-Z]+$/.test(attribute.name)) {
            // If the name contains only English characters
            slug = `pa_${attribute.name.toLowerCase().replace(/\s+/g, '')}`;
          } else {
            // If the name contains Arabic or other characters
            slug = `pa_${attribute.name.replace(/\s+/g, '-').toLowerCase()}`;
          }
  
          idMap[slug] = attribute.id;
        });
  
        setAttributeIdMap(idMap);
  
      } catch (error) {
        console.error('Error fetching attributes:', error);
      }
    };
  
    fetchAttributes(); // Call the function to fetch attributes
  
  }, []); // Empty dependency array to run only once on component mount
  

  const toggleGallery = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  const handleImportProduct = () => {
    if (product) {
      setSelectedProductId(product.id);
      // Perform any other actions needed for product import
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const username = formValues.username;
    const password = formValues.password;

    try {
      const formData = new FormData();
      formData.append('username', username);
      formData.append('password', password);

      const response = await fetch('https://gostookbackend.gostook.com/checkuserAliExpress.php', {
        method: 'POST',
        body: formData,
      });

      console.log(`${username} ",,,,," ${password}`);

      if (response.ok) {
        const data = await response.json();
        console.log('API Response:', data);
        console.log('Received Data:', data.user);

        if (data.status === 'success') {
          localStorage.setItem('userData', JSON.stringify(data.user));
          localStorage.setItem('isUser', data.token);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${data.message}`,
          });
          console.error('API Error:', data.message);
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `Failed to send data to the API`,
        });
        console.error('Failed to send data to the API');
      }
    } catch (error) {
      console.error('Error while sending data to the API:', error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `Failed to send data to the API`,
      });
    }
    setOpen(false);
  };

  return (
    <>
    {loading ? <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      backgroundColor:modeLabel === "dark mode" ? "#1e1e1e" : "white"
    }}
  >
    <CircularProgressThickness />
  </div> : 
    <div style={{backgroundColor:modeLabel === "dark mode" ? "#1e1e1e" : "white"}}>
    <ResponsiveAppBar/>
     <div className="product-details-container">
     <div className="product-info">
       <div className="image-container">
         <img
           src={selectedImage || (product && product.images[0].src)}
           alt={product && product.name}
           className="main-image"
           onClick={() => toggleGallery(product && product.images[0].src)}
         />
         <div className="additional-images">
           {product &&
             product.images.slice(1).map(image => (
               <img
                 key={image.id}
                 src={image.src}
                 alt={product.name}
                 className="additional-image"
                 onClick={() => toggleGallery(image.src)}
               />
             ))}
         </div>
       </div>
       <div className="details-container">
  <h2 style={{color:modeLabel === "dark mode" ? "white" : "black"}} className="product-name">{product && product.name}</h2>
  <p style={{textAlign:"center"}} className="product-price">Price: SAR {product && product.price}</p>
  <div className="attribute-list">
    {product &&
      product.attributes &&
      product.attributes.map((attribute, index) => (
        <div key={attribute.id} className="attribute-item">
          <label style={{color:modeLabel === "dark mode" ? "white" : "black"}} className="attribute-title">:{attribute.name}</label>
          <select className="input" defaultValue="">
            <option value="" disabled hidden>Options</option>
            {attribute.options.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

        </div>
      ))}
                <h2 className="mb-1" style={{ color: "#d9534f",  fontFamily: "Cairo,sans-serif",textAlign:"center",fontWeight:"900"}}>
                  {product.categories && product.categories.length > 0
                    ? product.categories.map(category => category.name).join(", ")
                    : "غير مصنف"}
                </h2>
  </div>
</div>


     </div>
     <div style={{textAlign:"center"}}>
     <Button
  variant="outlined"
  onClick={handleClickOpen}
  disabled={!product || !productVariations}
  sx={{
    borderColor: '#1b7d4f', // Border color
    color: 'white', // Text color
    backgroundColor:"#1b7d4f",
    marginTop:"70px",
    fontWeight: 'bold', // Font weight
    fontSize:"20px",
    textAlign:"center",
    fontFamily: "Cairo,sans-serif",
    '&:hover': {
      borderColor: '#d77a2b', // Hover border color
      color: '#d77a2b', // Hover text color
    },
  }}
>
  استــورد المنـتـج
</Button>
</div>
     <Dialog
       open={open}
       onClose={handleClose}
       aria-labelledby="form-dialog-title"
       dir="rtl"
     >
       <DialogTitle style={{fontFamily:"Cairo"}} id="form-dialog-title">تأكيـــد الطلب</DialogTitle>
       <form onSubmit={handleFormSubmit}>
         <DialogContent>
           <DialogContentText  style={{fontFamily:"Cairo"}} >أدخل اسم المستخدم وكلمة المرور لتسجيل الدخول.</DialogContentText>
           <TextField
             autoFocus
             margin="dense"
             id="username"
             label="اسم المستخدم"
             type="text"
             fullWidth
             value={formValues.username}
             onChange={(e) => setFormValues({ ...formValues, username: e.target.value })}
           />
           <TextField
             margin="dense"
             id="password"
             label="كلمة المرور"
             type="password"
             fullWidth
             value={formValues.password}
             onChange={(e) => setFormValues({ ...formValues, password: e.target.value })}
           />
         </DialogContent>
         <DialogActions>
           <Button style={{fontFamily:"Cairo"}} onClick={handleClose} variant="contained" color="secondary">
             إلغاء
           </Button>
           <Button onClick={handleFormSubmit} style={{marginRight:"5px",fontFamily:"Cairo"}} variant="contained" color="primary">
             تأكيــــد
           </Button>
           <Link to="/signup">
           <Button style={{color:"black",fontFamily:"Cairo"}} color="primary">
             غير مسجل
           </Button>
           </Link>
         </DialogActions>
       </form>
     </Dialog>
     <br />
     <br />
     <h2 style={{color:modeLabel === "dark mode" ? "white" : "black",textAlign:"center"}}>الوصف</h2>
     <hr style={{backgroundColor:"#1b7d4f",height:"2px",border:"1px solid #1b7d4f"}} />
     <div className="description" dangerouslySetInnerHTML={{ __html: product && product.description }} />
   </div>
       </div>
     }
   
    </>
  );
}

export default ProductDetails;
