// NotFound.js
import React from 'react';

function NotFound() {
  return (
    <div style={{display:"flex",justifyContent:"center",height:"100vh",placeItems:"center",textAlign:"center",flexDirection:"column"}}>
      <h1 style={{fontFamily:"Cairo",fontSize:"35px",fontWeight:"800"}}>404 Page Not Found</h1>
      <p style={{fontFamily:"Cairo",fontSize:"20px",fontWeight:"600"}}>The page you are looking for does not exist.</p>
    </div>
  );
}

export default NotFound;
