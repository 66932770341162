import React, { useState, useEffect } from 'react';
import StoreOrdersTable from './StoreOrdersTable'; // Adjust the import path as needed
import CircularProgress from '@mui/material/CircularProgress';
import HeaderAdmin from '../../components/dashboard/Header';

const AllStoresOrders = () => {
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStoresData = async () => {
      try {
        const response = await fetch('https://gostookbackend.gostook.com/getUsersMtagrData.php');
        const data = await response.json();
        setStores(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching stores data:', error);
        setLoading(false);
      }
    };

    fetchStoresData();
  }, []);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <HeaderAdmin/>
      <h1 style={{fontFamily:"Cairo",textAlign:"center",fontWeight:"900",border:"2px solid red",marginTop:"130px"}}>جميع طلبات المتاجر المسجلين لدينا على المنصة </h1>
      {stores.map((store) => (
        <StoreOrdersTable key={store.username} store={store} />
      ))}
    </div>
  );
};

export default AllStoresOrders;
