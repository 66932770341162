import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import image from "../../images/جو-ستوك.png"
import CustomizedSwitches from '../SwitcherLightAndDarkmode/Switcher';
import { useContext } from 'react';
import { ModeContext } from '../ModeContext/ModeContext';
import Person4Icon from '@mui/icons-material/Person4';
import { Link, useNavigate } from 'react-router-dom';
import "./Navbar.css"

const pages = ['الصفحة الرئيسية', 'المنتجات', 'اتصل بنا', 'من نحن', 'دخول', 'اختر ثيمك'];
const settings = ['الملف الشخصي'];

function ResponsiveAppBar() {
  const navigate = useNavigate();
  const { modeLabel } = useContext(ModeContext);

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const userDataString = localStorage.getItem('userData');
  const userData = userDataString ? JSON.parse(userDataString) : null;
  const username = userData ? userData.username : 'Guest';

  return (
    <AppBar sx={{ backgroundColor: modeLabel === 'dark mode' ? '#121212' : '#1b7d4f', direction: 'rtl', position: 'static' }}>
      <div style={{
        backgroundColor: "#71CC6D",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        height: "50px",
        fontFamily: "Cairo,sans-serif"
      }}>
        <Link to="/dashboard">
          <div
            style={{
              width: '40px',
              height: '40px',
              backgroundColor: '#1b7d4f',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Person4Icon style={{ fontSize: "30px", color: "white" }} />
          </div>
        </Link>
        <h4 className='texth4' style={{ color: "white" }}>
          منتجاتنا متوفرة الآن في أماكن كتيرة ! تشكيلة واسعة من المنتجات بأسعار تنافسية.. قريباً!
        </h4>
        <CustomizedSwitches />
      </div>
      <Container style={{ padding: "10px" }} maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <Link to="/">
              <img style={{ height: "90px", width: "150px" }} src={image} alt="logo" />
            </Link>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography key={page}
                    onClick={() => {
                      handleCloseNavMenu();
                      switch (page) {
                        case 'الصفحة الرئيسية':
                          navigate('/');
                          break;
                        case 'المنتجات':
                          navigate('/products');
                          break;
                        case 'اتصل بنا':
                          navigate('/contactus');
                          break;
                        case 'دخول':
                          navigate('/dashboarduser');
                          break;
                        case 'من نحن':
                          navigate('/aboutus');
                          break;
                        case 'اختر ثيمك':
                          window.open('http://gostook.com', '_blank');
                          break;
                        default:
                          break;
                      }
                    }} textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <Link to="/">
              <img style={{ height: "90px", width: "150px" }} src={image} alt="logo" />
            </Link>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', textAlign: "center", marginRight: "50px" } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={() => {
                  handleCloseNavMenu();
                  if (page === 'الصفحة الرئيسية') {
                    navigate('/');
                  } else if (page === 'المنتجات') {
                    navigate('/products');
                  } else if (page === 'اتصل بنا') {
                    navigate('/contactus');
                  } else if (page === 'دخول') {
                    navigate('/dashboarduser');
                  } else if (page === 'من نحن') {
                    navigate('/aboutus');
                  } else if (page === 'اختر ثيمك') {
                    window.open('http://gostook.com', '_blank');
                  }
                }}
                sx={{
                  my: 2,
                  color: 'black',
                  backgroundColor: 'white',
                  margin: "5px",
                  fontFamily: "Cairo,sans-serif",
                  fontSize: "17px",
                  borderRadius: "20px",
                  width: "150px",
                  '&:hover': {
                    backgroundColor: '#1b7d4f',
                    color: 'white'
                  }
                }}
              >
                {page}
              </Button>
            ))}
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="فتح الإعدادات">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={username} src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Link style={{ textDecoration: "none" }} to="/dashboarduser">
                    <Typography style={{ fontFamily: "Cairo", color: "black" }} textAlign="center">{setting}</Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
