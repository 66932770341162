import React from "react";
import image from "../images/3.jpg";
import "./Banner3.css";
import image1 from "../images/Layer 2.png";
import image2 from "../images/Layer 3.png";
import Fade from "react-reveal/Fade";

const Banner3 = () => {
  return (
    <div className="Banner3" style={{ marginTop: "110px",width:"100%" }}>
      <div className="centered-div">
        <img className="layer1" src={image1} alt="layer2" />
        <Fade bottom delay={500} duration={1500}>
          <div>
            <img className="layer2" src={image2} alt="layer3" />
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Banner3;
