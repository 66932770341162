import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import HeaderAdmin from './dashboard/Header';
import { useNavigate } from "react-router-dom";
const SupplierTable = () => {
  const [suppliers, setSuppliers] = useState([]);

  const history = useNavigate();

  useEffect(() => {
    // Check if adminToken exists in local storage
    const adminToken = localStorage.getItem("adminToken");
    if (!adminToken || adminToken.trim() === "") {
      // Navigate to "/admin" if adminToken is not present or empty
      history("/admin");
    }
  }, [history]);

  useEffect(() => {
    fetch('https://gostookbackend.gostook.com/getsupplyers.php')
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setSuppliers(data.suppliers);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    <div>
        <HeaderAdmin />
        <h1 style={{fontFamily:"Cairo",fontSize:"40px",fontWeight:"800",marginTop:"150px",textAlign:"center"}}>بيــنــات المـــورديــن</h1>
    <TableContainer component={Paper}>
      <Table style={{fontFamily:'Cairo'}}>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Category</TableCell>
            <TableCell>Supplier Number</TableCell>
            <TableCell>Store Number</TableCell>
            <TableCell>Store Name</TableCell>
            <TableCell>Supplier SKU</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {suppliers.map((supplier) => (
            <TableRow key={supplier.id}>
              <TableCell style={{fontFamily:'Cairo'}}>{supplier.id}</TableCell>
              <TableCell style={{fontFamily:'Cairo'}}>{supplier.name}</TableCell>
              <TableCell style={{fontFamily:'Cairo'}}>{supplier.netch}</TableCell>
              <TableCell style={{fontFamily:'Cairo'}}>{supplier.supplyerNumber}</TableCell>
              <TableCell style={{fontFamily:'Cairo'}}>{supplier.StoreNumber}</TableCell>
              <TableCell style={{fontFamily:'Cairo'}}>{supplier.storeName}</TableCell>
              <TableCell style={{fontFamily:'Cairo'}}>{supplier.supplyersku}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  );
};

export default SupplierTable;
