import React from 'react';
import "./Hello.css";
import Button from '@mui/material/Button';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Aboutgostook from '../AboutGostook/Aboutgostook';
import { ModeContext } from '../ModeContext/ModeContext';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import image from "../../images/hello.png"
import Categories from '../SliderJifCategories/SliderJifCategories';
import JIF1 from "../../components/JIF1"
const Hello = () => {
  const { modeLabel } = useContext(ModeContext);
  
  return (
    <div style={{ backgroundColor: modeLabel === 'dark mode' ? '#1e1e1e' : '#e9e9e9'}}>
    <div
      style={{
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center',
        backgroundColor: modeLabel === 'dark mode' ? '#121212' : '#1b7d4f',
        textAlign: 'center',
        justifyContent:"space-around"
        // borderBottomRightRadius: '100%',
        // borderBottomLeftRadius: '70%',
      }}>
      {/* First div */}
      <div style={{ marginRight: '10px' }}>
        <h2 className='round-label-mazan text-black mt-lg-5 ml-4 me-lg-5 me-2' style={{ color:'white'}}>
           ! مرحبا بك
        </h2>
        <p className='text-banner text-black me-lg-5 me-2' style={{ color:'white'}}>
  
  في جوستــوك، نقدم لك
   أفضل الحلول
   <br/>
    لتطوير مشروعك

 اكتشف عالم التجارة الإلكترونية بأسلوبنا الفريد
</p>

        <Link to="/signup">
        <Button className='But2' style={{backgroundColor:"white",color:"black",
        fontFamily:" Noto Sans Arabic",borderRadius:"20px",
        fontSize:"20px",
         boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',marginBottom:"30px" }} startIcon={<ArrowBackIosNewIcon />}>
        سجل الأن وابدء بمتجرك الالكتروني
      </Button>
      </Link>
      </div>

      {/* Second div */}
      <div className='ImageHello'>
        {/* Your image */}
        <img className='Image' src={image}
         alt="Your Image" style={{  objectFit: 'cover' }} />
      </div>
    </div>
     <JIF1/>
    <Aboutgostook/>
    </div>
  );
};

export default Hello;
