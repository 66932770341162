import React from 'react';
import './Banner1.css'; // Create a CSS file for styling
import image from "../images/3.1.png";
import image2 from "../images/2.1.png";
import image3 from "../images/hed.png";

const FloatingImages = () => {
  return (
    <div className="sea-background">
      <div className="sideDiv">
       <h2 className='electronicstext' >
           الإلكــترونيات
         </h2>   
         <h2 className='electronicstexteng'>
           ELECTRONICS
         </h2>     
    </div> {/* New div to be positioned next to parentFloat */}
      <div className='parentFloat'>
        <div className="floating-image1">
          <img src={image3} className='image2' alt='image1' />
        </div>
        <div className="floating-image2">
          <img src={image} className='image1' alt='image1' />
        </div>
        <div className="floating-image3">
          <img src={image2} className='image3' alt='image1' />
        </div>
      </div>
    </div>
  );
};

export default FloatingImages;
