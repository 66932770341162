import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import CircularProgress from '@mui/material/CircularProgress';

const ImportAttributesFromExternalAPI = (props) => {
    const { url, consumerKey, secretKey, rowId, onDataUpdate } = props;
    const [buttonClicked, setButtonClicked] = useState(false);
    const [importStatus, setImportStatus] = useState('');
    const [lastUpdated, setLastUpdated] = useState(localStorage.getItem(`lastUpdated_${rowId}`) || '');

    const woocommerceConsumerKey = consumerKey;
    const woocommerceConsumerSecret = secretKey;
    const woocommerceApiUrl = `${url}/wp-json/wc/v3/products/attributes`;

    const externalConsumerKey = 'ck_198703302120ef330806f9d5d1b0d5e7cd032c08';
    const externalConsumerSecret = 'cs_96230e77a2a2eddf102682ae70c993924a7a6821';
    const externalApiUrl = 'https://goostook.com/wp-json/wc/v3/products/attributes';

    const handleImportClick = async () => {
        setButtonClicked(true);
        setImportStatus('جاري التحديث');
        try {
            const externalApiResponse = await axios.get(externalApiUrl, {
                headers: {
                    Authorization: `Basic ${btoa(`${externalConsumerKey}:${externalConsumerSecret}`)}`,
                    'Content-Type': 'application/json',
                },
            });

            if (externalApiResponse.status === 200) {
                const externalApiData = externalApiResponse.data;

                if (Array.isArray(externalApiData) && externalApiData.length > 0) {
                    for (const attribute of externalApiData) {
                        const slugWithVeno = attribute.slug.replace('-', '');
                        const encodedSlug = encodeURIComponent(slugWithVeno);
                        const slugWithoutPercent = encodedSlug.replace(/%/g, '');
                        const truncatedSlug = slugWithoutPercent.substring(0, 28);

                        const data = {
                            name: attribute.name,
                            slug: truncatedSlug,
                            type: attribute.type,
                            order_by: attribute.order_by,
                            has_archives: true,
                        };

                        try {
                            const woocommerceApiResponse = await axios.post(woocommerceApiUrl, data, {
                                headers: {
                                    Authorization: `Basic ${btoa(`${woocommerceConsumerKey}:${woocommerceConsumerSecret}`)}`,
                                    'Content-Type': 'application/json',
                                },
                            });

                            if (woocommerceApiResponse.status === 201) {
                                console.log(`Attribute "${attribute.name}" updated successfully!`);
                            } else {
                                console.error(`Failed to update attribute "${attribute.name}" in WooCommerce. Response:`, woocommerceApiResponse.data);
                            }
                        } catch (woocommerceError) {
                            console.error(`Error updating attribute "${attribute.name}" in WooCommerce:`, woocommerceError);
                        }
                    }

                    const currentDate = new Date();
                    const formattedDate = `${currentDate.toLocaleString('ar-EG', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })} ${currentDate.toLocaleTimeString('ar-EG')}`;
                    setLastUpdated(formattedDate);
                    setImportStatus('تم التحديث بنجاح');
                    Swal.fire({
                        icon: 'success',
                        title: ' تم التحديث ',
                        text: 'تم تحديث البيانات بنجاح.. ',
                    });

                    // Store last updated date with rowId as part of the key in localStorage
                    localStorage.setItem(`lastUpdated_${rowId}`, formattedDate);

                    // Call the callback function with the updated data
                    onDataUpdate({
                        rowId,
                        lastUpdated: formattedDate,
                        importStatus: 'تم التحديث بنجاح',
                    });
                } else {
                    console.error('No attributes found in the external API data.');
                    setImportStatus('No attributes found');
                }
            } else {
                console.error('Failed to fetch data from the external API.');
                setImportStatus('Failed to fetch data');
            }
        } catch (error) {
            // console.error('Error fetching or updating attributes:', error);
            // setImportStatus('Error fetching or updating attributes');
        } finally {
            setButtonClicked(false);
        }
    };

    useEffect(() => {
        const storedLastUpdated = localStorage.getItem(`lastUpdated_${rowId}`);
        if (storedLastUpdated) {
            setLastUpdated(storedLastUpdated);
        }
    }, [rowId]);

    return (
        <div>
            {buttonClicked ? (
                <div>
                    <CircularProgress size={24} style={{ color: '#1565c0' }} />
                    <div>{importStatus}</div>
                </div>
            ) : (
                <div>
                    <button onClick={handleImportClick} style={{ fontFamily: 'Cairo' }} disabled={buttonClicked}>
                        {' '}
                        تحديث البيانات{' '}
                    </button>
                    <div>{importStatus}</div>
                    {lastUpdated && <div>{`آخر تحديث: ${lastUpdated}`}</div>}
                </div>
            )}
        </div>
    );
};

export default ImportAttributesFromExternalAPI;
