import React, { useState, useEffect, useRef, useContext } from "react";
import { useForm } from 'react-hook-form';
import { Button, TextField, Container, Typography } from '@mui/material'; // Import Material-UI components
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import ResponsiveAppBar from '../components/Navbar/Navbar';

import { ModeContext } from '../components/ModeContext/ModeContext';
const AdminForm = () => {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  const [token, setToken] = useState('');
  const { modeLabel } = useContext(ModeContext);
  const onSubmit = async (data) => {
    try {
      const response = await fetch('https://gostookbackend.gostook.com/authintcate.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();
      console.log(result);

      if (result.isAdmin) {
        setToken(result.token);
        localStorage.setItem('adminToken', result.token); // Store the token in localStorage
        console.log(result.token);
        navigate('/dashboard');
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'اسم المستخدم أو كلمة المرور غير صحيحة',
        });
      }
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      });
    }
  };

  return (
    <div style={{
      backgroundColor: modeLabel === "dark mode" ? "black" : "white",
      // Add other styles as needed
  }}>
        <ResponsiveAppBar/>
        <h2 style={{fontFamily:"Cairo",textAlign:"center",backgroundColor:"#71cc6d",color:"white"}}>هذه الــصـفـحة خــاصــة بالســادة المســئــوليــن</h2>
    <Container style={{height:"60vh",width:"80%",justifyContent:"center",display:"flex",flexDirection:"column"}}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ width: '80%', padding: '20px',textAlign: 'right',justifyContent:"center",display:"flex",flexDirection:"column"}}
      >
        <Typography  style={{fontFamily:"Cairo"}} variant="h4" align="center" gutterBottom>تسجيل الدخول</Typography>

        <TextField
          fullWidth
          variant="outlined"
          margin="normal"
          label="اسم المستخدم"
          dir="rtl"
          {...register('username')}
        />

        <TextField
          fullWidth
          variant="outlined"
          margin="normal"
          type="password"
          label="كلمة المرور"
          dir="rtl"
          {...register('password')}
        />

        <div style={{textAlign:"center"}}>
          <Button style={{width:"60%",backgroundColor:"#fadb81",color:"black",
          fontSize:"20px",fontFamily:"Cairo"}}  variant="contained" color="primary" size="large" type="submit">
            دخول
          </Button>
        </div>
      </form>
    </Container>
            
    </div>
  );
};

export default AdminForm;
