import React from "react";
import ResponsiveAppBar from "../Navbar/Navbar";

const Header = ()=>{
    return(
        <div>
          <ResponsiveAppBar/>
        </div>
    )
}

export default Header;