import React, { useContext, useEffect, useRef, useState } from "react";
import "./ImportantNumbers.css";
import CountUp from "react-countup";
import { ModeContext } from "../ModeContext/ModeContext";
const ImportantNumbers = () => {
  const { modeLabel } = useContext(ModeContext);

  const [isVisible, setIsVisible] = useState(false);
  const countUpRef1 = useRef(null);
  const countUpRef2 = useRef(null);
  const countUpRef3 = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 } // Trigger when 50% of the element is visible
    );

    // Observe each counter item if the ref is not null
    countUpRef1.current?.nodeName && observer.observe(countUpRef1.current);
    countUpRef2.current?.nodeName && observer.observe(countUpRef2.current);
    countUpRef3.current?.nodeName && observer.observe(countUpRef3.current);

    // Clean up the observer on component unmount
    return () => observer.disconnect();
  }, []);

  return (
    <div style={{backgroundColor: modeLabel === 'dark mode' ? '#1e1e1e' : '#e9e9e9'}}>
      <h2 className="label-mazan3 text-black w-100 text-center mt-3" style={{color: modeLabel === 'dark mode' ? 'white' : 'black'}}>
        أرقــام تهمك
      </h2>
      <div className="counter-section">
        <div className={`counter-item ${isVisible ? "visible" : ""}`} ref={countUpRef1}>
         <div className="CounterDiv">
            <h2>
            <CountUp  style={{color:"white"}} start={0} end={70} duration={3} delay={3} /><span style={{color:"white"}}>K+</span>
            </h2>
          </div>
          <h1 className="counter-title">متجرا</h1>
        </div>
        <div className={`counter-item ${isVisible ? "visible" : ""}`} ref={countUpRef1}>
         <div className="CounterDiv">
            <h2>
            <CountUp  style={{color:"white"}} start={0} end={20} duration={3} delay={3} /><span style={{color:"white"}}>K+</span>
            </h2>
          </div>
          <h1 className="counter-title">مستخدم</h1>
        </div>
        <div className={`counter-item ${isVisible ? "visible" : ""}`} ref={countUpRef1}>
         <div className="CounterDiv">
            <h2>
            <CountUp  style={{color:"white"}} start={0} end={50} duration={3} delay={3} /><span style={{color:"white"}}>K+</span>
            </h2>
          </div>
          <h1 className="counter-title">منتج</h1>
        </div>
        <div className={`counter-item ${isVisible ? "visible" : ""}`} ref={countUpRef1}>
         <div className="CounterDiv">
            <h2>
            <CountUp  style={{color:"white"}} start={0} end={80} duration={3} delay={3} /><span style={{color:"white"}}>K+</span>
            </h2>
          </div>
          <h1 className="counter-title">مورد</h1>
        </div>
        <div className={`counter-item ${isVisible ? "visible" : ""}`} ref={countUpRef1}>
         <div className="CounterDiv">
            <h2>
            <CountUp  style={{color:"white"}} start={0} end={30} duration={3} delay={3} /><span style={{color:"white"}}>K+</span>
            </h2>
          </div>
          <h1 className="counter-title">شركة شحن</h1>
        </div>
        <div className={`counter-item ${isVisible ? "visible" : ""}`} ref={countUpRef1}>
         <div className="CounterDiv">
            <h2>
            <CountUp style={{color:"white"}} start={0} end={20} duration={3} delay={3} /><span style={{color:"white"}}>K+</span>
            </h2>
          </div>
          <h1 className="counter-title">منصة</h1>
        </div>
      </div>
    </div>
  );
};

export default ImportantNumbers;
