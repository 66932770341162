import React, { createContext, useState } from 'react';

// Create a context
export const ModeContext = createContext();

// Create a provider for the context
export const ModeProvider = ({ children }) => {
  const [modeLabel, setModeLabel] = useState('light mode');

  const toggleMode = () => {
    setModeLabel((prevLabel) => (prevLabel === 'light mode' ? 'dark mode' : 'light mode'));
  };

  return (
    <ModeContext.Provider value={{ modeLabel, toggleMode }}>
      {children}
    </ModeContext.Provider>
  );
};
