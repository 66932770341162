import React, { useState, useEffect } from "react";
import { Zoom } from "react-reveal";
import CircularProgressThickness from "./Spinner";
import "./MoreRequests.css"; // Import CSS file for styling
import { ModeContext } from "../ModeContext/ModeContext";
import { useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

function MoreRequests() {
  const { modeLabel } = useContext(ModeContext);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          "https://goostook.com/wp-json/wc/v3/products?consumer_key=ck_198703302120ef330806f9d5d1b0d5e7cd032c08&consumer_secret=cs_96230e77a2a2eddf102682ae70c993924a7a6821&per_page=100"
        );

        const shuffledProducts = response.data.sort(() => Math.random() - 0.5);
        const selectedProducts = shuffledProducts.slice(0, 8);

        setProducts(selectedProducts);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  if (loading) {
    return <CircularProgressThickness />;
  }

  return (
    <Zoom duration={1500} delay={300}>
      <div style={{ backgroundColor: modeLabel === "dark mode" ? "#1e1e1e" : "#e9e9e9", paddingBottom: "70px" }}>
        <h2 className="label-mazan text-black w-100 text-center mt-3" style={{ color: modeLabel === "dark mode" ? "white" : "black" }}>
          وصــل حديــثا
        </h2>
        <div className="my-5 containerr">
          {products.map((product, index) => (
            <div className="card" key={index}>
              <img style={{height:"380px"}} src={product.images && product.images.length > 0 ? product.images[0].src : ""} alt={product.name} />
              <div className="card-body">
                <h3 style={{ fontFamily: "Cairo",color:modeLabel === "dark mode" ? "white" : "black"}} > {product.name.substring(0, 20)} </h3>
                <p className="text-muted">{product.category}</p>
                {/* Link to the product details page with the specific product ID */}
                <Link style={{textDecoration:"none",color:"white"}} to={`/product/${product.id}`} className="custom-button">اقرء المزيد</Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Zoom>
  );
}

export default MoreRequests;
