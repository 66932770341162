import React from "react";
import "./ShowCircleTop.css";
import { Button } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { ModeContext } from "../ModeContext/ModeContext";
import { useContext } from 'react';
import { Link } from "react-router-dom";
import image1 from "../../images/New Project (2) (1).png"
import image2 from "../../images/New Project (2).png"
import image3 from "../../images/New Project (3).png"
import image4 from "../../images/length-line2.png"
import image5 from "../../images/doted-line1.png"
import image6 from "../../images/right-line3.png"
const ShowCircleTop = () => {
  const { modeLabel } = useContext(ModeContext);
  return (
    <div className="container">
      <div className="half-ellipse" style={{backgroundColor: modeLabel === 'dark mode' ? 'white' : '#1b7d4f'}}></div>
      <div style={{width: "100%" }}>
        <div
          style={{
            marginTop: "40px",
            width: "100%",
            backgroundColor: "",
        backgroundColor: modeLabel === 'dark mode' ? '#1e1e1e' : '#e9e9e9'
          }}
        >
          <h1 className="label-mazan text-black w-100 text-center mt-3" style={{color: modeLabel === 'dark mode' ? 'white' : 'black'}}>
            طريقك للبدء
          </h1>
          <div className="parent">
            <div className="parent1">
              <div className="parent11 child">
                <div className="NumberList">
                  <h1 style={{ color: "black", display: "flex", justifyContent: "center",
                   alignItems: "center", fontSize: "24px",color:"white" }}>1</h1>
                </div>
                <h2 className="H3" style={{ fontFamily: "Cairo"}}>انشاء متجرك الالكتروني </h2>
              </div>
              <div className="IMAGE">
              <img
  src={modeLabel === "light mode" ? image5 : image2}
  alt="imagelist"
/>

              </div>
              <div className="parent12 child">
                <div className="NumberList">
                  <h1 style={{ color: "black", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "24px",color:"white" }}>2</h1>
                </div>
                <h2 className="H3" style={{ fontFamily: "Cairo" }}> الاشتراك في الباقة المناسبة </h2>
              </div>
              <div className="IMAGE">
               <img
  src={modeLabel === "light mode" ? image5 : image2}
  alt="imagelist"
/>

              </div>
              <div className="parent13 child">
                <div className="NumberList">
                  <h1 style={{ color: "black", display: "flex",color:"white" ,justifyContent: "center", alignItems: "center", fontSize: "24px" }}>3</h1>
                </div>
                <h2 className="H3" style={{ fontFamily: "Cairo" }}> الربط معنا من خلال موقعنا الالكتروني </h2>
              </div>
            </div>
            <div className="vertcallImage">
            <div style={{visibility:"hidden"}}>
                <img src={modeLabel === "light mode" ?image4 : image3} alt="image"/>
            </div><div>
            <img src={modeLabel === "light mode" ? image4 : image3} alt="image"/>
               
            </div><div style={{visibility:"hidden"}} >
            <img src={modeLabel === "light mode" ? image4 : image3} alt="image"/>
           
            </div><div style={{visibility:"hidden"}}>
            <img src={modeLabel === "light mode" ? image4 : image3} alt="image"/>
                
            </div><div style={{visibility:"hidden"}} >
            <img src={modeLabel === "light mode" ? image4 : image3} alt="image"/>
             
            </div>
          </div>
          <div className="parent1">
              <div className="parent11 child">
                <div className="NumberList">
                  <h1 style={{ color: "black",color:"white", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "24px" }}>6</h1>
                </div>
                <h2 className="H3" style={{ fontFamily: "Cairo"}}>يتم تجهيز الطلب وتأكيده </h2>
              </div>
              <div className="IMAGE">
                <img src= {modeLabel === "light mode" ? image6 : image1 } alt="imagelist" />

              </div>
              <div className="parent12 child">
                <div className="NumberList">
                  <h1 style={{ color: "black",color:"white", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "24px" }}>5</h1>
                </div>
                <h2 className="H3" style={{ fontFamily: "Cairo" }}>يوصل الطلب تلقائيا من خلال التأكيد </h2>
              </div>
              <div className="IMAGE">
              <img src= {modeLabel === "light mode" ? image6 : image1 } alt="imagelist" />
                
              </div>
              <div className="parent13 child">
                <div className="NumberList">
                  <h1 style={{ color: "black",color:"white", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "24px" }}>4</h1>
                </div>
                <h2 className="H3" style={{ fontFamily: "Cairo" }}>اختيار المنتجات مع تحديد نسبة ربحك عليها</h2>
              </div>
            </div>
          </div>
          <div className="phonedivs">
        <div className="parent11 child">
                <div className="NumberList">
                  <h1 style={{ color: "black",color:"white", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "24px" }}>1</h1>
                </div>
                <h2 className="H3" style={{ fontFamily: "Cairo"}}> انشاء متجرك الالكتروني </h2>
              </div>
              <div className="IMAGE">
              <img src= {modeLabel === "light mode" ? image4 : image3 } alt="imagelist" />
              </div>
              <div className="parent11 child">
                <div className="NumberList">
                  <h1 style={{ color: "black",color:"white", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "24px" }}>2</h1>
                </div>
                <h2 className="H3" style={{ fontFamily: "Cairo"}}>الاشتراك في الباقة المناسبة</h2>
              </div>
              <div className="IMAGE">
              <img src= {modeLabel === "light mode" ? image4 : image3 } alt="imagelist" />
              </div>  <div className="parent11 child">
                <div className="NumberList">
                  <h1 style={{ color: "black",color:"white", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "24px" }}>3</h1>
                </div>
                <h2 className="H3" style={{ fontFamily: "Cairo"}}> الربط معنا من خلال موقعنا الالكتروني </h2>
              </div>
              <div className="IMAGE">
              <img src= {modeLabel === "light mode" ? image4 : image3 } alt="imagelist" />
              </div>  <div className="parent11 child">
                <div className="NumberList">
                  <h1 style={{ color: "black",color:"white", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "24px" }}>4</h1>
                </div>
                <h2 className="H3" style={{ fontFamily: "Cairo"}}> يتم تجهيز الطلب وتأكيده </h2>
              </div>
              <div className="IMAGE">
              <img src= {modeLabel === "light mode" ? image4 : image3 } alt="imagelist" />
              </div> 
               <div className="parent11 child">
                <div className="NumberList">
                  <h1 style={{ color: "black",color:"white", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "24px" }}>5</h1>
                </div>
                <h2 className="H3" style={{ fontFamily: "Cairo"}}>  يوصل الطلب تلقائيا من خلال التأكيد   </h2>
              </div>
              <div className="IMAGE">
              <img src= {modeLabel === "light mode" ? image4 : image3 } alt="imagelist" />
              </div>
              <div className="parent11 child">
                <div className="NumberList">
                  <h1 style={{ color: "black",color:"white", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "24px" }}>6</h1>
                </div>
                <h2 className="H3" style={{ fontFamily: "Cairo"}}> اختيار المنتجات مع تحديد نسبة ربحك عليها  </h2>
              </div>
        </div>
          <Link to ="signup">
          <Button style={{backgroundColor:"#1b7d4f",color:"black",
        fontFamily:" Cairo",fontSize:"25px",borderRadius:"20px",marginTop:"30px",marginBottom:"30px",color:"white" }} startIcon={<ArrowBackIosNewIcon />}>
          سجل الآن وابدء مشروعك 
      </Button>
      </Link>
        </div>

      </div>
    </div>
  );
};

export default ShowCircleTop;
