import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import axios from 'axios';
import ImportAttributesFromExternalAPI from '../test';

// Define the theme
const theme = createTheme({
  typography: {
    fontFamily: 'Cairo, sans-serif'
  }
});

// Styled components for TableCell and TableRow
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  textAlign: 'right', // Right align content
  whiteSpace: 'nowrap', // Prevent text wrapping
  overflow: 'hidden', // Hide overflow
  textOverflow: 'ellipsis', // Show ellipsis for overflow
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function CustomizedTables() {
  const [userData, setUserData] = useState([]);
  const [showAll, setShowAll] = useState(false);

  // Fetch data using useEffect
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://gostookbackend.gostook.com/getUsersMtagrData.php');
        const data = await response.json();
        setUserData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // Toggle showAll state
  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  // Function to render cell content based on showAll state
  const renderCellContent = (content) => {
    return showAll ? content : content.slice(0, 15);
  };
  
  

  return (
    <>
    <h1 style={{fontFamily:"Cairo",marginTop:"130px",textAlign:"center"}}>أصحــاب المتــاجر</h1>
    <EmotionThemeProvider theme={theme}>
      <TableContainer style={{ marginTop: '50px', direction: 'rtl'}} component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table" style={{ width:"95%",margin:"auto"}}>
          <TableHead>
            <TableRow>
              <StyledTableCell>الاسم الأول</StyledTableCell>
              <StyledTableCell width={200}>اسم العائلة</StyledTableCell>
              <StyledTableCell width={200}>اسم المستخدم</StyledTableCell>
              <StyledTableCell>كلمة المرور</StyledTableCell>
              <StyledTableCell>الرابط</StyledTableCell>
              <StyledTableCell>الهاتف</StyledTableCell>
              <StyledTableCell>البريد الإلكتروني</StyledTableCell>
              <StyledTableCell>العنوان</StyledTableCell>
              <StyledTableCell>المفتاح السري</StyledTableCell>
              <StyledTableCell>المفتاح العمومي</StyledTableCell>
              <StyledTableCell> تحديث الخصائص </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
{userData.map((user, index) => (
  <StyledTableRow key={user.username}>
    <StyledTableCell component="th" scope="row">{renderCellContent(user.firstname)}</StyledTableCell>
    <StyledTableCell>{renderCellContent(user.lastname)}</StyledTableCell>
    <StyledTableCell>{renderCellContent(user.username)}</StyledTableCell>
    <StyledTableCell>{renderCellContent(user.password)}</StyledTableCell>
    <StyledTableCell>{renderCellContent(user.url)}</StyledTableCell>
    <StyledTableCell>{renderCellContent(user.phone)}</StyledTableCell>
    <StyledTableCell>{renderCellContent(user.email)}</StyledTableCell>
    <StyledTableCell>{renderCellContent(user.address)}</StyledTableCell>
    <StyledTableCell>{renderCellContent(user.secretKey)}</StyledTableCell>
    <StyledTableCell>{renderCellContent(user.consumerKey)}</StyledTableCell>
    <StyledTableCell>
      <ImportAttributesFromExternalAPI
        url={user.url}
        consumerKey={user.consumerKey}
        secretKey={user.secretKey}
        rowId={index} // Use a unique identifier like index
      />
    </StyledTableCell>
  </StyledTableRow>
))}

          </TableBody>
        </Table>
        <div style={{textAlign:"center"}}>
        <Button variant="contained" onClick={toggleShowAll} style={{ marginTop: '20px' }}>
          {showAll ? 'عرض أقل' : 'عرض المزيد'}
        </Button>
        </div>
      </TableContainer>
    </EmotionThemeProvider>
    </>
  );
}
